// src/pages/competition.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

import '../styles/pages/competition.css';

import HeroSection from '../sections/HeroSection';
import TestimonialsSlider from '../components/TestimonialsSlider';
import CtaSection from '../sections/CtaSection';
import CompetitionWinners from '../sections/CompetitionWinners';
import CompetitionFAQs from '../sections/CompetitionFAQs';

import ProductList from '../components/ProductList';

const Competition = () => {

    const festivalTestimonials = [
      {
        text: "Just landing and reflecting on an incredible day of truffly goodness at the Truffle Festival yesterday in Plumpton. Truffles were the enticer, but it was definitely the human connections that made the day for me.",
        author: "Jesper Launder",
      },
      {
        text: "Some really lovely teams and what a fantastic day at The Great British Truffle Festival.",
        author: "Stef Roulett",
      },
      {
        text: "Amazing Day yesterday at the Truffle Hound Competition and a very proud Dad moment with our boy Tembo coming 1st in the Gold Competition! We’ve had a great couple of weeks hunting and then competing & we have truffle supplies to last a lifetime!",
        author: "Jonathan McGowan",
      },
      {
        text: "Today’s event has been fantastic. It’s been really well organised. The food’s been fantastic. It’s been great meeting all the truffle dogs and the handlers, it’s been really really good.",
        author: "Prof. Paul Thomas",
      },
    ];
  
    const [products, setProducts] = useState([]);
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/gbtf-products`);
                const productsData = response.data;

                const productsWithPrices = await Promise.all(
                    productsData.map(async (product) => {
                        try {
                            const priceResponse = await axios.get(`${process.env.REACT_APP_API_URL}/gbtf-prices/${product.default_price}`);
                            return {
                                ...product,
                                price: priceResponse.data.unit_amount / 100
                            };
                        } catch (priceError) {
                            console.error('Error fetching price:', priceError);
                            return product;
                        }
                    })
                );

                setProducts(productsWithPrices);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, []);

    return (
        <div className="competition-page">
            
            <HeroSection 
            title="The Great British Truffle Hound Competition"
            description="Join us for an exciting and fun-filled Truffle Hound Competition! This event celebrates the incredible skills of truffle-hunting dogs and their handlers. Below you can find the winners for the year 2023, competition rules, and more details on how to enter and prepare."
            showButton={true}
            buttonText="Check the rules"
            // buttonTarget="https://aw.thegreatbritishtrufflefestival.co.uk/v1/storage/buckets/6682bad8000f921592bc/files/66a7b923000afa3651f2/view?project=6681b8b70006e5f43807"
            buttonTarget="/competition-rules"
            />
            <br />
            <div className="content-wrapper">
                <h2>About</h2>
                <p>Welcome to The Great British Truffle Hound Competition, a celebration of the incredible partnership between truffle hunters and their canine companions. This competition not only showcases the remarkable skills of truffle-hunting dogs but also honors the legacy of pioneers like Marion Dean, who founded the first truffle hound competition in the UK. As you explore the details of this year’s event, we invite you to learn more about the inspiration behind it by reading our blog post and Marion Dean's story <a href="https://blog.thegreatbritishtrufflefestival.co.uk/index.php/2024/08/29/why-we-believe-in-the-great-british-truffle-hound-competition/">here.</a> Whether you’re here to compete or simply to enjoy the event, we’re thrilled to have you join our community of passionate truffle enthusiasts.</p>
            </div>
            <br />
        

            <CtaSection 
                id="cta1"
                title="Meet The Judges"
                description="Our judges are an incredible group of experts with a wealth of experience and expterise and are dedicated to ensuring a fair and exciting competition."
                linkText="Meet The Judges"
                linkUrl="/judges"
                onClick={() => window.location.href = '/judges'}
            />
            <br />
            <br />

            <TestimonialsSlider testimonials={festivalTestimonials} />
            <br />
            <br />

            <div className="text-center mt-4">
                <CompetitionWinners />
                <CompetitionFAQs />
            </div>
            <br />
            <h2 className="text-center mb-4">Reserve Your Spot In The Competition</h2>
            <div id="competition-products-container">
                
                <ProductList
                    initialCategory="Competitors"
                    categories={['Competitors']}
                    products={products}
                    noParking="true"
                />
            </div>

        </div>
    );
};

export default Competition;
