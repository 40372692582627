// src/pages/Signup.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import '../styles/pages/auth.css';

const Signup = () => {
  const { signup } = useAuthContext();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userType, setUserType] = useState('guest');
  const [tickets, setTickets] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {

    // if this page been passed from the successful checkout, then collect details for signup
    const urlParams = new URLSearchParams(window.location.search);
    const emailFromURL = urlParams.get('email');
    const userTypeFromURL = urlParams.get('userType');
    const ticketsFromURL = urlParams.get('tickets');

    if (emailFromURL) setEmail(emailFromURL);
    if (userTypeFromURL) setUserType(userTypeFromURL);
    if (ticketsFromURL) setTickets(JSON.parse(decodeURIComponent(ticketsFromURL)));

    // Store user data in local storage for persistence
    if (emailFromURL) localStorage.setItem('email', emailFromURL);
    if (userTypeFromURL) localStorage.setItem('userType', userTypeFromURL);
    if (ticketsFromURL) localStorage.setItem('tickets', ticketsFromURL);
  }, []);

  // submit the signup
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signup(email, password, name, userType, tickets);
      // After successful signup, navigate to the user portal
      navigate('/my-festival');
    } catch (error) {
      setError('Failed to sign up. Please check your details and try again.');
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h1>Sign Up</h1>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Sign Up</button>
        </form>
        <p>
          Already have an account? <a href="/login">Login</a>
        </p>
      </div>
    </div>
  );
};

export default Signup;
