import React from 'react';

import HeroSection from '../sections/HeroSection';
import TestimonialsSlider from '../components/TestimonialsSlider';
import AboutSection from '../sections/AboutSection';
import WhatToExpectSection from '../sections/WhatToExpectSection';
import CtaSection from '../sections/CtaSection';
import OurStorySection from '../sections/OurStorySection';
import HelpfulInfoSection from '../sections/HelpfulInfoSection';
import FaqSection from '../sections/FaqSection';

import heroLogo from '../assets/images/tgbtf_logo_white_500.webp';

const handleSubscribeClick = () => {
  const subscribeModal = new window.bootstrap.Modal(document.getElementById('subscribeModal'));
  subscribeModal.show();
};

const Home = () => {

  const festivalTestimonials = [
    {
      text: "Just landing and reflecting on an incredible day of truffly goodness at the Truffle Festival yesterday in Plumpton. Truffles were the enticer, but it was definitely the human connections that made the day for me.",
      author: "Jesper Launder",
    },
    {
      text: "Some really lovely teams and what a fantastic day at The Great British Truffle Festival.",
      author: "Stef Roulett",
    },
    {
      text: "Amazing Day yesterday at the Truffle Hound Competition and a very proud Dad moment with our boy Tembo coming 1st in the Gold Competition! We’ve had a great couple of weeks hunting and then competing & we have truffle supplies to last a lifetime!",
      author: "Jonathan McGowan",
    },
    {
      text: "Today’s event has been fantastic. It’s been really well organised. The food’s been fantastic. It’s been great meeting all the truffle dogs and the handlers, it’s been really really good.",
      author: "Prof. Paul Thomas",
    },
  ];


  // const countdownDate = new Date('November 16, 2024 00:00:00').getTime(); // Set your event date here

  return (

    <div className="home-page">
      <div>
        <HeroSection 
          title="Unearth the Magic"
          description="November 16th, 2024 | 9am-5pm | Plumpton Racecourse"
          image={heroLogo}
          showButton={true}
          buttonText="Book Now"
          buttonTarget="/tickets"
        />
        <div className="flex-container" role="main">
          <AboutSection />
          <WhatToExpectSection />
          <CtaSection 
            id="cta1"
            title="Join the Movement"
            description="Secure your early bird spot and be part of preserving the legacy and delight of British truffles. Join the celebration of our natural heritage and culinary culture."
            linkText="Get Your Tickets"
            linkUrl="/tickets"
            onClick={() => window.location.href = '/tickets'}
          />
          <OurStorySection />
          <br />
          
          <h2>Testimonials</h2>
          <TestimonialsSlider testimonials={festivalTestimonials} />
          <br />
          <CtaSection 
            id="cta2"
            title="Subscribe for our 2025 festival!"
            description="These things come around again fast... Make sure you don't miss next years festival by subscribing for an update here!"
            linkText="Subscribe"
            linkUrl="#subscribeModal"
            onClick={handleSubscribeClick}
          />
          <br />

          <HelpfulInfoSection />
          <FaqSection />
        </div>
      </div>
    </div>
    
  );
  
};

export default Home;
