import React from 'react';
import venue from '../assets/images/venue_map_image.webp';
import '../styles/sections/helpfulInfoSection.css'

const HelpfulInfoSection = () => (
    <section id="helpful-info" className="py-5">
        <div className="container">
            <h1 className="text-center mb-4">Getting To The Venue</h1>
            <p className="text-center mb-4">
                The Great British Truffle Festival 2024 is being held at Plumpton Racecourse, East Sussex, BN7 3AL on November 16th.<br />
                The venue is just a 10 minute walk from Plumpton train station and a 30-minute drive from the city of Brighton.
            </p>

            <div className="row mb-4">
                <div className="col-md-6 mb-3">
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe
                            title="Find us on Google Maps"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.9604964417344!2d-0.06701932390958798!3d50.924447753122855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4875896146fe946d%3A0x528c9cc883279ab4!2sPlumpton%20Racecourse%20Ltd!5e0!3m2!1sen!2suk!4v1705843029798!5m2!1sen!2suk"
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">By Road</h5>
                            <p className="card-text">Plumpton Racecourse is situated on Plumpton Lane 1.5 miles off the B2116, and 8 miles from the A23, leaving at the Pyecombe services junction. Our signposted main entrance on Plumpton Lane is 200 yards beyond the bridge on the left (coming from Ditching) and 0.5 miles past the train station (coming through Plumpton Green), up the hill on the right.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-6 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">By Rail</h5>
                            <p className="card-text">Plumpton station is situated at the bottom of the course and is less than a 5-minute walk to the North entrance. Plumpton is served by a regular train service between London Victoria and Eastbourne/Hastings.</p>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-3">
                    <div className="card h-100">
                        <div className="card-body">
                            <h5 className="card-title">Parking</h5>
                            <p className="card-text">Parking is available on site however, we do encourage you to car-share where possible. All-day parking will be £4 and must be purchased online with your ticket order or via this page here.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row my-4">
                <div className="col-12">
                    <img src={venue} alt="Helpful Info" className="img-fluid" />
                </div>
            </div>
        </div>
    </section>
);

export default HelpfulInfoSection;
