// src/components/TestimonialsSlider.js

import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/components/testimonialsSlider.css';

const TestimonialsSlider = ({ testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="testimonials-slider" id="testimonials">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-item">
            <blockquote className="testimonial-text">“{testimonial.text}”</blockquote>
            <p className="testimonial-author">- {testimonial.author}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

TestimonialsSlider.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TestimonialsSlider;
