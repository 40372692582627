// src/pages/HaveAGo.js
import React from 'react';
import '../styles/theme.css';
import '../styles/pages/haveago.css';

import HeroSection from '../sections/HeroSection';

import truffleHoundTrainingImg from '../assets/images/competition_dog.webp'; 
import goodier from '../assets/images/goodier.png'; 
import topSniffer from '../assets/images/topSniffer.png'
import bestDressedHoundAndHunter from '../assets/images/houndandhunter.png'
import bestDressed from '../assets/images/bestDressed.png'

const HaveAGo = () => {
    return (
        <div>
            <HeroSection
                title="Have a Go Arena"
                description="This bit is all about having fun. This experience is for newbies who want to see if they have what it takes to find a truffle in our Have-a-Go arena. "

            />
            <section className="training-section py-5">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <h2>Truffle Hound Training</h2>
                            <p>Unlock the hidden potential of your dog’s sense of smell with our personalised, one-on-one 'have-a-go' Truffle Hound Training sessions. Led by our seasoned experts, these hands-on sessions are designed to introduce you and your dog to the exciting world of scent training and truffle hunting. If you're new to to scent training with your dog, our quick trainings offer a fun and educational experience for both of you. Your dog will learn the basics of scent detection in a kind and supportive environment, helping you both to begin to develop the confidence and precision needed to become a true truffle hunting partnership.</p>
                        </div>
                        <div className="col-md-6">
                            <img src={truffleHoundTrainingImg} alt="Truffle Hound Training" className="img-fluid rounded" />
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <img src={goodier} alt="Truffle Hound Training" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-6">
                            <h2>Our Have-A-Go Head Trainer</h2>
                            <p>Meet Micheala Goodier, our amazing head trainer at the Have-A-Go Arena! With over 30 years of experience in canine training. Having worked with organisations like the Royal Army Veterinary Corps and the Defence Science and Technology Laboratory, Micheala is a true expert in the field. </p>
                            <p>She’s trained dogs for everything from Search and Rescue to Explosive Detection - and now she’s here to help you and your dog discover their truffle-hunting potential!</p>
                            <p>If you're totally new to truffle hunting make sure you register for this event on the day; Micheala and the team are here to guide you with some top hunting tips. Get ready for a fun and educational experience with a pro truffle scent training team who are as passionate about your dog as you are!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="arena-info-section py-5 bg-light">
                <div className="container">
                    <h2>What is the Have a Go Arena All About?</h2>
                    <p>The Have-A-Go arena operates from 11am-1pm and 2pm-5pm. The area is lush and grassy but safely enclosed - for the more excitable pooch.</p>
                    <p>This arena is open to everyone who wants to test their dog's truffle-finding abilities. The experience is light-hearted and fun, with no strict rules. Here’s how it works:</p>
                    <div className="info-cards">
                        <div className="card">
                            <div className="card-body">
                                <h5>Participation</h5>
                                <ul>
                                    <li>You and your dog will have up to 10 minutes to participate per go.</li>
                                    <li>Dogs must remain on their leads at all times.</li>
                                    <li>Sign-up is required for a specific time slot; if you miss it, you'll need to sign up for another.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5>Guidance</h5>
                                <ul>
                                    <li>A facilitator will guide you and your dog throughout the process, helping you follow these basic principles:</li>
                                    <li>Ask your dog to sit.</li>
                                    <li>Maintain eye contact.</li>
                                    <li>Encourage them to smell the truffle scent.</li>
                                    <li>Immediately reward them and use your working word to indicate the truffle.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h5>Materials Provided</h5>
                                <ul>
                                    <li>Hides with cotton wool</li>
                                    <li>Truffle oil</li>
                                    <li>Dog treats</li>
                                    <li>A mentor to assist with the process</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="awards-section py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Fun Awards and Prizes</h2>
                    <div className="row">
                    <div className="col-md-4 mb-4">
    <div className="card">
        <img src={bestDressedHoundAndHunter} className="card-img-top" alt="Waggiest Tail" />
        <div className="card-body">
            <h5 className="card-title">Best Dressed Hound & Hunter</h5>
            <p className="card-text">This award celebrates the most stylish duo in the field! Whether you and your dog are matching or have complementary outfits, this prize goes to the pair that turns heads with their coordinated fashion and flair.</p>
        </div>
    </div>
</div>
<div className="col-md-4 mb-4">
    <div className="card">
        <img src={bestDressed} className="card-img-top" alt="Best Dressed Hunter & Hound" />
        <div className="card-body">
            <h5 className="card-title">Best Dressed Pooch</h5>
            <p className="card-text">Showcase your dog’s unique style and flair. This award goes to the pooch with the most creative and fashionable outfit.</p>
        </div>
    </div>
</div>
<div className="col-md-4 mb-4">
    <div className="card">
        <img src={topSniffer} className="card-img-top" alt="Top Sniffer" />
        <div className="card-body">
            <h5 className="card-title">Top Sniffer</h5>
            <p className="card-text">Recognise the dog with the best sense of smell! This award is for the top dog that can sniff out hidden treasures in the have-a-go arena like a pro.</p>
        </div>
    </div>
</div>

                    </div>
                </div>
            </section>
            
        </div>
    );
};

export default HaveAGo;
