import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/melissa.css';
import melissaImg from '../assets/images/melissa.png';
import TestimonialsSlider from '../components/TestimonialsSlider';

const Melissa = () => {


  const melissaTestimonials = [
    {
      text: "Melissa is a great, knowledgeable and friendly trainer. After the expert guidance from Melissa, our dog found autumn truffles and we learnt how to help him hunt more efficiently and understand his behaviour.",
      author: "Rob Edlin",
    },
    {
      text: "Melissa's work in the field of mycology and her particular interests in the symbiotic relationships between fruiting fungus/truffles and trees is going to prove essential in maintaining a healthy ecological/environmental balance in this under-recognised area in the future.",
      author: "Nicholas Sokolow",
    },
    {
      text: "Her vast knowledge of all things fungi, truffles, and indeed anything woodland related was astonishing. Her people manner is excellent and any clients of hers will have a great day out, no matter how great or small their foraging finds are.",
      author: "Katrine Carstens",
    },
    {
      text: "Anyone thinking of doing the Zoom truffle hound training with Melissa then do it, you certainly won’t be disappointed. Melissa’s knowledge and expertise is incredible. She explains in depth all about truffles, what signs to look for, how to check and smell that a truffle is there, all while being very careful and respectful of the woodland.",
      author: "Nicky Locke",
    },
    {
      text: "I couldn’t recommend her enough. She gave us great tips and exercises, and we have been having such a fun time with our doggie. We can't wait to go into the woods with her next winter.",
      author: "Roni Guetta",
    },
    {
      text: "Wow! Just finished my first session with Melissa and found her passion for truffles and training extremely motivating. Melissa was so quick to read the abilities of Winnie (my spoilt cockapoo) and provide the right training exercises that were both challenging and enjoyable for her.",
      author: "Joe Toley",
    },
    {
      text: "Thank you so much for a truly mind-altering experience, you have opened a door into nature that will help my clients, my children’s clients, and spread the word for generations to come.",
      author: "Julia",
    },
    {
      text: "Thank you so much for the walk today. We found it hugely interesting, certainly very informative and well organised. Do please let us know if there are other walks.",
      author: "Bryan and Honor Hayes",
    },
  ];

    

    useEffect(() => {
        // Add RSS feed script when component mounts
        const scriptId = 'rssapp-wall-script';
        if (!document.getElementById(scriptId)) {
            addExternalScript('https://widget.rss.app/v1/wall.js', scriptId);
        }

        // Cleanup function to remove the script when component unmounts
        return () => {
            const script = document.getElementById(scriptId);
            if (script) {
                script.remove();
            }
        };
    }, []);

    const addExternalScript = (src, id) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = id;
        script.async = true;
        document.body.appendChild(script);
    };

    // Suppress the ResizeObserver loop limit exceeded error
    useEffect(() => {
        const observerErrorHandler = (e) => {
            if (e.message === 'ResizeObserver loop limit exceeded') {
                e.stopImmediatePropagation();
            }
        };
        window.addEventListener('error', observerErrorHandler);

        return () => {
            window.removeEventListener('error', observerErrorHandler);
        };
    }, []);

    return (
        <div>

            <div className="breadcrumb">
                <p><Link to="/">↩ Back To Main Page</Link></p>
            </div>

            <div className="profile-flex-container">
                <img src={melissaImg} height="200" alt="Melissa Waddingham" className="profile-pic" />
                <div className="mel-container">
                    <h1>Melissa Waddingham</h1>
                    <h5 className="brand-font">Founder</h5>
                    <p>
                        I have early memories of foraging with my grandfather in Champagne, France gathering Chanterelles. I was never aware that he secretly slipped out and came home with the odd truffle in his pocket, apparently only for special customers and family as he ran an auberge that was very popular with the locals for his sublime food. It was my father who told me about my Grandfather and his truffle antics, when I presented him with ten or eleven of my own! I found this instantly gratifying realizing that my passion truly was in the blood. My friends tell me that I have always foraged for something be it a fossil, flower, plant, piece of attractive wood but I would never come home alone, this is very true and I seemed to have a natural affinity to what was around me.
                    </p>
                    <p>
                        I am a self taught professional truffle hunter and wild mushroom picker. I did a forestry degree at Plumpton College in 2008 to support my already growing knowledge about fungi which was at that time an evolving hobby which grew into a passion for amateur mycology and what lay ahead in the quest for fungal and woodland knowledge.
                    </p>
                    <p>
                        In the spring and autumn months I run mushroom forays, truffle hunts, talks, courses and throughout the year I provide truffle hound training days for your truffle hound to be.
                    </p>
                    <p>
                        I also manage wild truffle woodlands for the sustainable production of truffle and give consultancy advice and offer fungi and truffle surveys for private woodland owners.
                    </p>
                    <p>
                        I was the original founder of The UK Truffle Festival and Dog Championships 2022-2023 and now I am pursuing a new role and founding the Great British Truffle Festival 2024 under new partnership with Michael Corcoran. A new venture which fills me with excitement for the next one and the future, especially as the last two were such a great success.
                    </p>
                </div>
            </div>
            
            <TestimonialsSlider testimonials={melissaTestimonials} />
            
            <div id="rssapp-wall-container" style={{ height: 'auto', overflow: 'auto' }}>
                <rssapp-wall id="_zh5YUTsWwfTxi616"></rssapp-wall>
            </div>

        </div>
    );
}

export default Melissa;
