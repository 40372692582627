import React from 'react';
import '../styles/sections/ourStorySection.css'

const OurStorySection = () => (
    <section id="our-story">
    <div className="tagline">
        <h1>"Celebrating and Sustaining the Great British Truffle"</h1>
    </div>
        <h2>Our Story</h2>

        <div className="who-we-are row">
            <div className="the-story col-md-6">


                {/* Mission */}
                <h3>Shaping a Sustainable Future for Truffle Hunting</h3>
                <div className="mission-statement">
                    <p>At The Great British Truffle Festival, we're all about loving truffles responsibly. Our festival isn't just a celebration; it's a movement towards sustainable truffle hunting that doesn't harm our woodlands. We believe everyone should enjoy truffles in a way that's good for our planet. Through education, phenomenal food, canine companionship and community efforts, we're here to spread the word on how to treasure truffles without hurting their natural home.</p>
                </div>

                {/* Founding Story */}
                <h3>Beginnings</h3>
                <div className="festival-story">
                    <p>Founded by <a href="/melissa">Melissa</a>, a renowned forager and woodland sustainability advocate, The Great British Truffle Festival is her vision brought to life. With decades of experience wandering the hills of the South Downs which provide a backdrop to the festival, Melissa's deep connection with woodland sustainability and truffle foraging has shaped a festival that's not just an event, but a journey into the heart of truffle appreciation.</p>
                </div>
            </div>

            {/* Guiding Values */}
            <div className="the-story col-md-6">
                <h3>Values</h3>
                <div className="core-values">
                    <ul>
                        <li><strong>Truffle Hounding: </strong> We celebrate the indispensable role of dogs in sustainable truffle hunting, emphasizing the deep bond between humans and their canine companions. Our value lies in promoting practices that protect these valuable ecosystems while honouring our furry friends' key contribution to truffle discovery.</li>
                        <li><strong>Truffle Hunting: </strong> Our commitment to truffle hunting goes beyond the thrill of the find; it's about respecting and preserving the environments where truffles flourish. We advocate for sustainable foraging methods that ensure the vitality of truffle habitats and their surrounding communities for future generations.</li>
                        <li><strong>Gastronomy: </strong> Gastronomy at our festival is a feast for the senses, showcasing the finest local produce and truffle-infused creations. We value the art of cooking and the celebration of local flavours, bringing together foodies, chefs, restaurants and producers to explore the rich culinary culture nurtured by truffles.</li>
                        <li><strong>Mycology Club: </strong> Our Mycology Club dives into the fascinating world of fungi, illuminating how truffles contribute to our planet's ecological balance. We value hands-on learning and sharing knowledge about the critical role of truffles and fungi in ecosystems, fostering a community passionate about mycological exploration and conservation.</li>
                    </ul>
                </div>
            </div>

        </div>
    </section>
);

export default OurStorySection;
