// src/pages/Experts.js

import React, { useState, useEffect } from 'react';
import '../styles/pages/experts.css';
import { expertsData } from '../data/expertsData';
import PeopleList from '../components/PeopleList';
import HeroSection from '../sections/HeroSection';

const Experts = () => {
    const [selectedRole, setSelectedRole] = useState('All');

    // Handle hash fragment in URL on page load
    useEffect(() => {
        const hash = window.location.hash.replace('#', '');  // Get the hash fragment
        if (hash && ['Speakers', 'Workshops', 'Panelists', 'All'].includes(hash)) {
            setSelectedRole(hash);  // Set the selected role based on the hash
        }
    }, []);  // This runs only once when the component is mounted

    // Filter the experts based on selected role
    const filterExperts = (role) => {
        return expertsData.filter((expert) =>
            role === 'All' ? true : expert.role.includes(role)
        );
    };

    // Description rendering function
    const getDescription = (role) => {
        switch (role) {
            case 'Speakers':
                return (
                    <>
                        <p>Meet our esteemed speakers who will provide fascinating insights into the world of truffles, fungi, and sustainability.</p>
                        <p>These experts will share their deep knowledge through engaging talks, covering everything from truffle cultivation to the importance of ecological preservation.</p>
                    </>
                );
            case 'Workshops':
                return (
                    <>
                        <p>Join our interactive workshops where you’ll gain hands-on experience and practical skills related to truffle hunting, mushroom cultivation, and more.</p>
                        <p>Jesper and Sarah will be holding a stall aimed at having a deeper, more detailed look at truffles. They will have a display highlighting an array of our different truffle species, not all gourmet edibles, with microscope slides to allow you to work out which species they are. It's an opportunity to get hands on with a number of our native truffle species and chat with the team who have years of experience between them as mycologists and finders of truffles without the help of a dog. </p>
                    </>
                );
            case 'Panelists':
                return (
                    <>
                        <p>Join our expert panelists as they engage in a groundbreaking debate about the future of UK truffle hunting regulations. They will explore key issues such as sustainability, conservation, and the potential need for stricter controls, accreditation schemes, or licensing.</p>
                        <p>With perspectives from truffle hunters, scientific experts, plantation owners, and conservationists, this discussion will tackle the balance between promoting truffle hunting and ensuring the protection of woodland ecosystems for future generations.</p>
                    </>
                );
            default:
                return (
                    <>
                        <p>Throughout the day, you'll have the opportunity to engage with leading experts in the field who will share their knowledge through captivating talks, interactive workshops, and live demonstrations. From the science of truffle cultivation to the artistry of cooking with these prized fungi, our experts will guide you through a fascinating journey that combines tradition with modern ecological practices.</p>
                        <p>The festival schedule is packed with diverse activities, ensuring there's something for everyone - whether you're here to learn, taste, or train your truffle-hunting dog.</p>
                    </>
                );
        }
    };

    return (
        <div className="experts-section">

            <HeroSection
                title="Meet the Experts"
                description="At The Great British Truffle Festival, we celebrate the rich intersection of natural science, gastronomy, dogs, and sustainability - and we've brought along some key voices."
            />
        
            <div className="content-wrapper">

                <div className="chips-container">
                    <button
                        className={`chip ${selectedRole === 'All' ? 'selected' : ''}`}
                        onClick={() => setSelectedRole('All')}
                    >
                        All
                    </button>
                    <button
                        className={`chip ${selectedRole === 'Speakers' ? 'selected' : ''}`}
                        onClick={() => setSelectedRole('Speakers')}
                    >
                        Speakers
                    </button>
                    <button
                        className={`chip ${selectedRole === 'Workshops' ? 'selected' : ''}`}
                        onClick={() => setSelectedRole('Workshops')}
                    >
                        Workshops
                    </button>
                    <button
                        className={`chip ${selectedRole === 'Panelists' ? 'selected' : ''}`}
                        onClick={() => setSelectedRole('Panelists')}
                    >
                        Panelists
                    </button>
                </div>
                
                <div className="description-experts">
                    {getDescription(selectedRole)}
                </div>

            </div>

            <PeopleList data={filterExperts(selectedRole)} selectedRole={selectedRole} />
        
        </div>
    );
};

export default Experts;
