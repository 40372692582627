// src/components/ALCMenu.js

import React from 'react';
// import QuantitySelector from './QuantitySelector';

// const ALCMenu = ({ products, getCartItemQuantity, handleAddToCart, increaseQuantity, decreaseQuantity }) => {
//     const normalCateringProduct = products.find(product => product.id === 'prod_QKsoavjsWdY2gy');

import { useProductContext } from '../context/ProductContext';
import QuantitySelector from './QuantitySelector';


const ALCMenu = ({ products, getCartItemQuantity, handleAddToCart, increaseQuantity, decreaseQuantity }) => {
    const { meatProduct } = useProductContext();

    return (
        <div className="menu-card">
            <h2>The Great British Truffle À La Carte Menu</h2>
            <br />

            <h4>Nibbles</h4>
            <br />
            <p>Harveys Best Sourdough, Cultured Butter, £4</p>
            <p>[Add Truffle Butter, £2]</p>
            <br />
            <p>British Woodlands Mushroom Soup, £8</p>
            <p>[Add Truffle, £2]</p>
            <p>-</p>
            <br />

            <h4>Mains</h4>
            <br />
            <p>Truffle Infused Macaroni Cheese, £16</p>
            <p>[Add lion's mane, £2]</p>
            <br />
            <p>Truffle Salt & Pepper Squid, £16</p>
            <br />
            <p>South Downs Venison Slider with Truffle Aioli, £16</p>
            <p>-</p>
            <br />

            <h4>Sides</h4>
            <br />
            <p>Truffle Fries, £5</p>
            <p>[Add Truffle Mayo, £2]</p>
            <br />
            <p>Charred Three Cornered Leek, Purple Kale & Butterbeans, £7</p>
            <br />
            <p>Foraged Winter Leaf & Herb Salad, £7</p>
            <p>-</p>
            <br />

            <h4>Desserts</h4>
            <br />
            <p>Bramble Brownie, Truffle Chantilly, £6</p>
            <br />
            <p>Apple Truffle Doughnut, £6</p>
            <br />

            <h4>Meal</h4>
            <p>Book online and get 10% off!</p>
            <p>Main, Side & Dessert, <s>£29</s> £26</p>
            <p></p>

            {/* Meat Qty Selector */}
            {meatProduct && (
                <div className="menu-row">
                    <div className="menu-item-name"><p>Online Meal Deal, <s>£29</s> £26</p></div>
                    <div className="quantity-selector-wrapper">
                        <QuantitySelector
                            product={meatProduct}
                            quantity={getCartItemQuantity(meatProduct.id)}
                            onAddToCart={handleAddToCart}
                            onIncrease={increaseQuantity}
                            onDecrease={decreaseQuantity}
                        />
                    </div>
                </div>
            )}

        </div>
    );
};

export default ALCMenu;
