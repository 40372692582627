// src/pages/PasswordReset.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../styles/pages/passwordReset.css';

function PasswordReset() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        const token = localStorage.getItem('appwriteToken');
        const userId = localStorage.getItem('appwriteUserId');

        try {
            await axios.post('/api/appwrite/updateAccount', {
                accountId: userId,
                newPassword: password,
                token
            });
            setSuccess(true);
            setTimeout(() => {
                navigate('/user-portal');
            }, 2000);
        } catch (error) {
            console.error('Failed to update password:', error);
            setError('Failed to update password. Please try again.');
        }
    };

    return (
        <div className="password-reset-container">
            <div className="password-reset-content">
                <h1 className="password-reset-heading">Reset Your Password</h1>
                {error && <p className="error-message">{error}</p>}
                {success ? (
                    <p className="success-message">Password updated successfully! Redirecting to your portal...</p>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="password">New Password</label>
                            <input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="confirm-password">Confirm New Password</label>
                            <input
                                type="password"
                                id="confirm-password"
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="submit-button">Update Password</button>
                    </form>
                )}
            </div>
        </div>
    );
}

export default PasswordReset;
