import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/cancel.css'; // Import the CSS file for styling

function Cancel() {
    return (
        <div className="cancel-container">
            <h1 className="cancel-heading">Payment Canceled</h1>
            <p className="cancel-message">It looks like your payment was not completed. If you encountered any issues or have any questions, please don't hesitate to contact our support team.</p>
            <div className="cancel-button-container">
                <Link to="/cart" className="cancel-button">Try Again</Link>
                <Link to="/contact" className="cancel-button">Contact Support</Link>
            </div>
        </div>
    );
}

export default Cancel;
