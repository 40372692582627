import React from 'react';

const Terms = () => {
    return (
        <div className="container mt-4">
            <h1>Terms and Conditions</h1>
            
            <h2>1. Introduction</h2>
            <p>Welcome to The Great British Truffle Festival website. These terms and conditions outline the rules and regulations for the use of our website and the purchase of tickets.</p>
            
            <h2>2. Definitions</h2>
            <p>In these terms and conditions, "we", "us", and "our" refer to The Great British Truffle Festival, and "you" refers to you, the person using our website or purchasing tickets.</p>
            
            <h2>3. Acceptance of Terms</h2>
            <p>By accessing this website and/or purchasing tickets, you accept these terms and conditions in full. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.</p>
            
            <h2>4. Ticket Purchase and Refunds</h2>
            <p>All ticket sales are final. Once purchased, tickets cannot be refunded or exchanged. In the event of cancellation or postponement of the event, you will be offered a full refund or the option to transfer your ticket to the rescheduled date.</p>
            
            <h2>5. Event Conduct</h2>
            <p>We reserve the right to refuse entry or eject any person from the event who is behaving inappropriately or in a manner that disrupts the event. You agree to comply with all event rules and regulations.</p>
            
            <h2>6. Liability</h2>
            <p>We are not responsible for any personal injury, loss, or damage to personal property that occurs during the event. You attend the event at your own risk.</p>
            
            <h2>7. Privacy</h2>
            <p>Your privacy is important to us. Please review our Privacy Policy, which explains how we collect, use, and protect your personal information.</p>
            
            <h2>8. Intellectual Property</h2>
            <p>All content on this website, including text, graphics, logos, and images, is the property of The Great British Truffle Festival or its content suppliers and is protected by copyright laws. You may not use, reproduce, or distribute any content from this website without our prior written permission.</p>
            
            <h2>9. Changes to Terms</h2>
            <p>We reserve the right to modify these terms and conditions at any time. Any changes will be posted on this page, and it is your responsibility to review these terms and conditions periodically. Your continued use of the website and purchase of tickets after any changes have been made constitutes your acceptance of the new terms and conditions.</p>
            
            <h2>10. Governing Law</h2>
            <p>These terms and conditions are governed by and construed in accordance with the laws of the United Kingdom, and you irrevocably submit to the exclusive jurisdiction of the courts in that location.</p>
            
            <h2>11. Contact Information</h2>
            <p>If you have any questions about these terms and conditions, please contact us at info@thegreatbritishtrufflefestival.co.uk.</p>
        </div>
    );
};

export default Terms;
