import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HaveAGo = ({ isEditing, setIsEditing }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    dogName: '',
    dogBreed: '',
    dogAge: '',
    dogHealth: '',
    safetyAgreement: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted', formData);
    // Here you can add your form submission logic, e.g., sending data to your server
    setIsEditing(false);
    navigate('/thank-you');
  };

  return (
    <div className="have-a-go-page">
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">
            The Great British Truffle Hound Competition Have-A-Go {isEditing ? 'Edit' : 'Registration'}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Mobile Telephone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogName" className="form-label">Dog's Name</label>
              <input
                type="text"
                className="form-control"
                id="dogName"
                name="dogName"
                value={formData.dogName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogBreed" className="form-label">Dog's Breed</label>
              <input
                type="text"
                className="form-control"
                id="dogBreed"
                name="dogBreed"
                value={formData.dogBreed}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogAge" className="form-label">Dog's Age</label>
              <input
                type="number"
                className="form-control"
                id="dogAge"
                name="dogAge"
                value={formData.dogAge}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogHealth" className="form-label">Dog's Health</label>
              <textarea
                className="form-control"
                id="dogHealth"
                name="dogHealth"
                value={formData.dogHealth}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="safetyAgreement"
                name="safetyAgreement"
                checked={formData.safetyAgreement}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="safetyAgreement">
                I agree to the health and safety terms and conditions.
              </label>
            </div>
            <button type="submit" className="btn btn-primary">{isEditing ? 'Save' : 'Register'}</button>
          </form>
        </div>
      </section>
    </div>
  );
};

export default HaveAGo;
