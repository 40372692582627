// src/components/PurchaseList.js

import React from 'react';
import QuantitySelector from './QuantitySelector';
import PropTypes from 'prop-types';
import '../styles/components/purchaseList.css';

const PurchaseList = ({ items, onAddToCart, onIncrease, onDecrease }) => {
    console.log("items in purchase list: ", items);
    return (
        <div className="purchase-list">
            {items.map((item, index) => (
                <div key={index} className="purchase-item">
                    <img src={item.images[0]} alt={item.name} className="purchase-item-image" />
                    <div className="purchase-item-details">
                        <h5>{item.name}</h5>
                        <p>Total: £{(item.price * item.quantity).toFixed(2)}</p>
                    </div>
                    <QuantitySelector
                        product={item}
                        quantity={item.quantity}
                        onAddToCart={onAddToCart}
                        onIncrease={onIncrease}
                        onDecrease={onDecrease}
                    />
                </div>
            ))}
        </div>
    );
};

PurchaseList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onAddToCart: PropTypes.func.isRequired,
    onIncrease: PropTypes.func.isRequired,
    onDecrease: PropTypes.func.isRequired,
};

export default PurchaseList;
