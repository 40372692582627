import React from 'react';

const Privacy = () => {
    return (
        <div className="container mt-4">
            <h1>Privacy Policy</h1>
            
            <h2>1. Introduction</h2>
            <p>We value your privacy and are committed to protecting your personal information. This privacy policy outlines how we collect, use, and protect your information when you visit our website and purchase tickets.</p>
            
            <h2>2. Information We Collect</h2>
            <p>We may collect the following types of information:</p>
            <ul>
                <li><strong>Personal Information:</strong> This includes your name, email address, phone number, and payment information when you purchase tickets or contact us.</li>
                <li><strong>Usage Data:</strong> We collect information on how you use our website, such as your IP address, browser type, pages visited, and the time and date of your visit.</li>
            </ul>
            
            <h2>3. How We Use Your Information</h2>
            <p>We use your information for the following purposes:</p>
            <ul>
                <li>To process your ticket purchases and provide you with confirmation and updates.</li>
                <li>To improve our website and services by analyzing how you use our site.</li>
                <li>To communicate with you regarding your purchases, upcoming events, and special offers.</li>
                <li>To comply with legal obligations and protect our rights and interests.</li>
            </ul>
            
            <h2>4. Sharing Your Information</h2>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties except in the following circumstances:</p>
            <ul>
                <li>With trusted service providers who assist us in operating our website, conducting our business, or providing services to you, provided they agree to keep your information confidential.</li>
                <li>When we believe release is appropriate to comply with the law, enforce our site policies, or protect our or others' rights, property, or safety.</li>
                <li>With your consent.</li>
            </ul>
            
            <h2>5. Security of Your Information</h2>
            <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee its absolute security.</p>            
            
            <h2>6. Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal information. You may also object to or restrict our processing of your data. To exercise these rights, please contact us at info@thegreatbritishtrufflefestival.co.uk.</p>
            
            <h2>7. Cookies</h2>
            <p>We use cookies to enhance your experience on our website. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser (if you allow) that enables the site's or service provider's systems to recognise your browser and capture and remember certain information.</p>
            
            <h2>8. Third-Party Links</h2>
            <p>Our website may contain links to third-party sites. These third-party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites.</p>
            
            <h2>9. Changes to Our Privacy Policy</h2>
            <p>We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this privacy policy periodically for any changes.</p>
            
            <h2>10. Contact Us</h2>
            <p>If you have any questions about this privacy policy, please contact us at info@thegreatbritishtrufflefestival.co.uk.</p>
        </div>
    );
};

export default Privacy;
