//  src/pages/Sponsors.js

import React from 'react';
import '../styles/pages/sponsors.css';
import { sponsorsData } from '../data/sponsorsData';
import PeopleList from '../components/PeopleList';

const SponsorsAndPartners = () => {
    
    return (
        <div className="sponsors-section">
            <h2 className="sponsors-title">Sponsors and Partners</h2>
           
            <PeopleList data={sponsorsData} />
        </div>
    );
};

export default SponsorsAndPartners;
