// src/userPortal/pages/guests/Tickets.js
import React from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { useProductContext } from '../../../context/ProductContext';

const Tickets = () => {
  const { purchases } = useAuthContext();
  const { products, loading } = useProductContext();

  if (loading) return <div>Loading...</div>;

  const getProductDetails = (itemId) => {
    return products.find(product => product.id === itemId);
  };

  return (
    <div className="tickets-content">
      {purchases.length === 0 ? (
        <p>No tickets purchased yet.</p>
      ) : (
        purchases.map((ticket, index) => {
          const productDetails = getProductDetails(ticket.itemId);
          return (
            <div key={index} className="ticket-item">
              <h3>{productDetails ? productDetails.name : ticket.itemName}</h3>
              <p>Amount: {ticket.itemCost}</p>
              <p>Currency: GBP</p>
              <p>Purchased at: {new Date(ticket.purchased).toLocaleString()}</p>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Tickets;
