//src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Credits from './pages/Credits';

import Home from './pages/Home';
import Melissa from './pages/Melissa';

import Competition from './pages/Competition';
import Judges from './pages/Judges';
import CompetitionRules from './pages/CompetitionRules';
import JuniorCompetitionRules from './pages/JuniorCompetitionRules';
import HaveAGo from './pages/HaveAGo';
import FineFoods from './pages/FineFoods';
import Exhibitors from './pages/Exhibitors';
import Experts from './pages/Experts';
import Sponsors from './pages/Sponsors';
import Market from './pages/Market';

import Tickets from './pages/Tickets';
import Cart from './pages/Cart';
import Success from './pages/Success';
import Cancel from './pages/Cancel';

import Login from './pages/Login';
import Signup from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import UserPortal from './userPortal/pages/UserPortal';

import { CartProvider } from './context/CartContext';
import AuthProvider from './context/AuthContext';
import ProductProvider from './context/ProductContext'; 
import PrivateRoute from './lib/ProtectedRoute';
import ScrollToTop from './lib/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
          <CartProvider>
            <ProductProvider>
              <div className="App">
                <Header />
                  <Routes>
                    <Route path="/" element={<Home />} />

                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/credits" element={<Credits />} />

                    <Route path="/tickets" element={<Tickets />} />
                    <Route path="/success" element={<Success />} />
                    <Route path="/cancel" element={<Cancel />} />
                    <Route path="/cart" element={<Cart />} />

                    <Route path="/melissa" element={<Melissa />} />
                    <Route path="/exhibitors" element={<Exhibitors />} />
                    <Route path="/sponsors" element={<Sponsors />} />
                    <Route path="/haveAGo" element={<HaveAGo />} />
                    <Route path="/fineFoods" element={<FineFoods />} />
                    <Route path="/experts" element={<Experts />} />
                    <Route path="/competition" element={<Competition />} />
                    <Route path="/competition-rules" element={<CompetitionRules />} />
                    <Route path="/junior-competition-rules" element={<JuniorCompetitionRules />} />
                    <Route path="/judges" element={<Judges />} />
                    <Route path="/market" element={<Market />} />

                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/password-reset" element={<PasswordReset />} />
                    <Route path="/my-festival" element={
                      <PrivateRoute>
                        <UserPortal />
                      </PrivateRoute>
                    } />
                    
                  </Routes>
                <Footer />
              </div>
            </ProductProvider>
          </CartProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
