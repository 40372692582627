// src/components/LogoScroller.js

import React from 'react';
import '../styles/components/logoScroller.css';

const LogoScroller = ({ images }) => {
    return (
        <div className="logo-scroller-container">
            <div className="logo-scroller">
                {images.map((image, index) => (
                    <div className="logo-item" key={index}>
                        <img src={image} alt={`Logo ${index + 1}`} className="logo-image" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LogoScroller;
