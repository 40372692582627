import React from 'react';
import HeroSection from '../sections/HeroSection';
import '../styles/pages/competitionRules.css'; 

import banner1 from '../assets/images/rulesBanners/1.png';
import banner2 from '../assets/images/rulesBanners/2.png';
import banner3 from '../assets/images/rulesBanners/3.png';
import banner4 from '../assets/images/rulesBanners/4.png';
import banner5 from '../assets/images/rulesBanners/5.png';
import banner6 from '../assets/images/rulesBanners/6.png';

const JuniorCompetitionRules = () => {
    return (
        <div className="competition-rules-page">
            <HeroSection 
                title="The Junior Great British Truffle Hound Competition Rules"
                description=""
                showButton={true}
                buttonText="Download the PDF"
                buttonTarget="https://aw.thegreatbritishtrufflefestival.co.uk/v1/storage/buckets/6682bad8000f921592bc/files/66a7bc5a002c21458f79/view?project=6681b8b70006e5f43807"

            />
            
            <div className="container py-5">
                <h1 className="text-center mb-4">The Junior Great British Truffle Hound Competition 2024</h1>
                
                <div className="rules-content-wrapper">
                    <div className="rules-text-content">
                        <p>Welcome to the Junior Truffle Hound Competition! We’re thrilled to have you and your fantastic truffle-hunting dogs join us for a day of adventure and discovery at the racecourse. This year, we have three groups for Juniors, just like the main competition: Bronze, Silver, and Gold.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner1} alt="Banner 1" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p><strong>How it works</strong></p>
                        <p><strong>Group Placement:</strong> You and your dog will be placed into a group based on your experience level. We’ll ask you about your experience through an online questionnaire beforehand. While we consider your preferred group, our head judge will make the final decision to ensure everyone is placed as fairly as possible.</p>
                        <p><strong>Run Times:</strong> Your run time will be chosen randomly and provided to you before the event. Hides will be refreshed periodically throughout the day to keep things fair and exciting!</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner2} alt="Banner 2" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p><strong>Before Your Run:</strong> Be sure to be in the waiting area by the Lewes Grandstand at least 10 minutes before your run time. One of our friendly volunteer judges will call you when it’s your turn and guide you to the competition course.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Important Notes</h2>
                        <p>Stay Out of the Competition Arena: Please do not enter the competition arena before your run. This helps keep the competition fair and organized.</p>
                        <p>One Round Only: There is only one round in all competitions, so make it count!</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner3} alt="Banner 3" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <h2>What to Expect</h2>
                        <p>Get ready for an incredible day at the racecourse, working with your best friend to find hidden truffles! Not only will you be showcasing your truffle-hunting skills, but you’ll also be part of a fun and educational experience. This is a fantastic opportunity to bond with your dog, compete, and have an unforgettable adventure.</p>
                        <p><strong>Good luck to all our junior competitors!</strong></p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Overview</h2>
                        <p><strong>Juniors</strong></p>
                        <p>Minimum age entry: 7<br />Max age entry: 17<br />On lead or off lead<br />Can compete in any level: Bronze, Silver or Gold</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner4} alt="Banner 4" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p><strong>Gold</strong><br />- Advanced to Pro -<br />Baits: undisclosed<br />Flags: 20<br />Search Time: 14 mins<br />Bait Depth: All buried baits<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                        <p><strong>Silver</strong><br />- Intermediate to Advanced -<br />Baits: 13<br />Flags: 13<br />Search Time: 12 mins<br />Bait Depth: A mixture of above-ground and buried baits<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                        <p><strong>Bronze</strong><br />- Just starting out -<br />Baits: 8<br />Flags: 8<br />Search Time: 10 mins<br />Bait Depth: All above-ground baits<br />Truffle Type: A mixture of truffle oil and real British truffles</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner5} alt="Banner 5" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <h2>Groups</h2>
                        <p>1. When the dog indicates, the handler is to place a flag at the point of indication.</p>
                        <p>2. If the indication is particularly active (e.g., full digging rather than just scratching), the handler is to end the indication or call the dog back.</p>
                        <p>3. Buried baits are up to 2 inches deep below ground level.</p>
                        <p>4. Your run ends upon placing your last flag, at 14 minutes for Gold, 12 minutes for Silver, and at 10 minutes for Bronze, or when the handler retires.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner6} alt="Banner 6" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p>5. The location of flags is recorded by the judge and then scored appropriately.</p>
                        <p>6. Handlers will not be told if the location of the flags is correct or not until the end of the competition, where you may request a copy of your scoresheet.</p>
                        <p>7. The area will have a coordinate system of markers around the edge to assist in confirming the location of baits and determining the accuracy of your flag placement. (Think of the game ‘Battleship’). Each square = 2x2m.</p>
                        <p>8. Real truffles will be in a ‘shell’ that will allow odor to escape but also protect the truffle.</p>
                        <p>9. Distractions such as wildlife droppings, various obstacles, other dogs' odor, horse or human odor, etc. will naturally be included in the search area.</p>
                        <p>10. The arena will be reset in between each run and baits refreshed periodically throughout the day.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Scoring</h2>
                        <p><strong>Points Awarded</strong></p>
                        <p>5 points for a correct truffle oil find<br />10 points for a correct real truffle find<br />1 point for each full minute remaining on the clock</p>
                    </div>

                    <div className="rules-text-content">
                        <p><strong>Points Deducted</strong></p>
                        <p>Minus 3 points for incorrect flag placement<br />Minus 2 points for a 2nd dig<br />Minus 5 points for a 3rd dig and every dig after<br />Minus 5 points for eating or removing bait<br />Minus 2 points for a 1st toileting in search area<br />Minus 5 points for a 2nd toileting in the search area<br />Minus 5 points for a 3rd toileting or every time after in search area</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Allowances</h2>
                        <p>Rewards/Treats can be given at no loss of points.<br />No loss of points for treats dropped<br />No loss of points for treating.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Disqualification</h2>
                        <p>No disqualifications unless the dog is unfairly treated.</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Plus Time</h2>
                        <p>In the event of a tie on points:<br />Step 1: Dog with least minus points<br />Step 2: Fastest dog<br />Step 3: Placings will be tied</p>
                    </div>

                    <div className="rules-text-content">
                        <h2>Important Information</h2>
                        <p>Everyone will be notified of their category and run time via email two weeks before the event. If we have still not received your completed registration form by this date, we cannot guarantee entry into your preferred category.</p>
                    </div>
                    <div className="rules-image-container">
                        <img src={banner4} alt="Banner 4" className="rules-image" />
                    </div>

                    <div className="rules-text-content">
                        <p>If you intend on using the whistle command with your dog, please let us know by emailing us at info@thegreatbritishtrufflefestival.co.uk.</p>
                        <p>Please make sure you arrive at the festival in plenty of time.</p>
                        <p>Once you arrive, you will need to check-in at the competitor check-in point and collect your lanyard.</p>
                        
                        <p>Please be at the check-in point waiting area 10 minutes before your run time.</p>
                        <p>Please make sure your dog has toileted prior to your run. Toileting in the arena will incur penalization [please see the rules]. The reason for this is to avoid contamination of the baits and therefore ensure a fair and consistent environment for all competitors.</p>
                        
                        <p>Dogs may wear belly bands, the penalty rules will still apply should the dog attempt to toilet in the arena.</p>
                        <p>If you miss your run time, we will do our best to slot you in at a later time, however we cannot guarantee this.</p>
                        <p>Please make sure your dogs are fit and well to participate.</p>
                        
                        <p>Bitches in season can compete this year but they will go last in the varying categories.*</p>
                        <p>Please make sure your dogs are vaccinated to compete.*</p>
                        <p>No dogs to compete under 5 months old.</p>
                        
                        <p>Compulsory Conservation Awareness Multiple Questionnaire to be filled before going into the ring for Adults and Juniors.*</p>
                        <p><em>* Represents changes or new rules added for 2024 to hopefully make the competition a fair and balanced event for all the competitors</em></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JuniorCompetitionRules;
