import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import QuantitySelector from './QuantitySelector';
import { CartContext } from '../context/CartContext';
import { useProductContext } from '../context/ProductContext';
import '../styles/components/productList.css';

const ProductList = ({ initialCategory, categories: initialCategories, noParking = false }) => {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(initialCategory);

    const { products, loading, error } = useProductContext();
    const { cartItems, addItemToCart, increaseQuantity, decreaseQuantity } = useContext(CartContext);

    const categories = useMemo(() => initialCategories || ['Tickets', 'Competitors', 'Experiences'], [initialCategories]);

    useEffect(() => {
        if (!loading && !error) {
            const categorizedProducts = categories.reduce((acc, category) => {
                acc[category] = products.filter(product => {
                    if (noParking) {
                        return product.metadata[category] === "true";
                    }
                    return product.metadata[category] === "true" || product.metadata["Parking"] === "true";
                });
                return acc;
            }, {});

            setFilteredProducts(categorizedProducts[selectedCategory] || []);
        }
    }, [selectedCategory, categories, products, loading, error, noParking]);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const getCartItemQuantity = (productId) => {
        const cartItem = cartItems.find(item => item.id === productId);
        return cartItem ? cartItem.quantity : 0;
    };

    if (loading) {
        return <p className="text-center">Loading tickets...</p>;
    }

    if (error) {
        return <p className="text-center">Failed to load products: {error}</p>;
    }

    return (
        <div>
            {categories.length > 1 && (
                <div className="filter-section text-center mb-4">
                    <div className="filter-chips">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`chip ${selectedCategory === category ? 'selected' : ''}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>
            )}

            <div className="product-list">
                {filteredProducts.length > 0 ? (
                    filteredProducts.map((product, index) => (
                        <div key={index} className="product-card">
                            <img src={product.images[0]} alt={product.name} className="card-img-top" />
                            <div className="card-body">
                                <h5 className="card-title">{product.name}</h5>
                                {product.price !== undefined ? (
                                    <p className="price">Price: £{product.price.toFixed(2)}</p>
                                ) : (
                                    <p className="price">Price: Not available</p>
                                )}
                                <QuantitySelector
                                    product={product}
                                    quantity={getCartItemQuantity(product.id)}
                                    onAddToCart={addItemToCart}
                                    onIncrease={increaseQuantity}
                                    onDecrease={decreaseQuantity}
                                />
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center">No products available in this category.</p>
                )}
            </div>

            <div className="text-center mt-4 cart-button">
                <Link to="/cart" className="btn btn-secondary">Go to Cart</Link>
            </div>
        </div>
    );
};

export default ProductList;
