// src/pages/Cart.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PurchaseList from '../components/PurchaseList';
import { CartContext } from '../context/CartContext';
import axios from 'axios'; 
import '../styles/pages/cart.css';
import { useAuthContext } from '../context/AuthContext';

const Cart = () => {
    const { cartItems, increaseQuantity, decreaseQuantity } = useContext(CartContext);
    const { user } = useAuthContext();
    console.log("cartItems for Cart Page: ", cartItems)

    const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    // Function to handle the checkout process
    const handleCheckout = async () => {
        try {
            let userId = user ? user.$id : null;
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/gbtf-create-checkout-session`, {
                cart: cartItems,
                userId
            });

            console.log('Checkout session response:', response);

            const { url } = response.data;

            // Redirect to Stripe Checkout
            window.location.href = url;
        } catch (error) {
            console.error('Error during checkout:', error);
            alert('There was an error initiating the checkout process. Please try again later.');
        }
    };

    return (
        <div className="container mt-4">
            <h2 className="text-center">Your Cart</h2>
            {cartItems.length > 0 ? (
                <div>
                    <PurchaseList
                        items={cartItems}
                        onIncrease={increaseQuantity}
                        onDecrease={decreaseQuantity}
                    />
                    <div className="cart-total">
                        <h4>Total: £{totalPrice.toFixed(2)}</h4>
                    </div>
                    <button className="btn btn-primary mt-4" onClick={handleCheckout}>Checkout</button>
                </div>
            ) : (
                <p className="text-center">Your cart is empty. Continue Hunting For <Link to="/tickets">Truffle Tickets...</Link></p>
            )}
        </div>
    );
};

export default Cart;
