// src/userPortal/userTypeConfig.js

import Registration from './pages/competitors/Registration';
import HaveAGo from './pages/guests/HaveAGo';
import Tickets from './pages/guests/Tickets';

const userTypeConfig = {
  guest: [
    {
      name: 'Tickets',
      component: Tickets,
    },
    {
      name: 'Register for Have-A-Go',
      component: HaveAGo,
      showEdit: true,
    },
  ],
  competitor: [
    {
      name: 'Tickets',
      component: Tickets,
    },
    {
      name: 'Register for Competition',
      component: Registration,
      showEdit: true,
    },
  ],
  
};

export default userTypeConfig;
