// src/sections/CompetitionFAQs.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/sections/competitionFaqs.css';

const CompetitionFAQs = () => {

    return (
        <section className="faq-section py-5">
            <div className="container">
                <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                <div className="accordion" id="faqAccordion">
                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingOne">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Who are the Judges?
                            </button>
                        </h3>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                Our judges are an incredible group of experts with years of experience in the truffle industry. They bring a wealth of knowledge and are dedicated to ensuring a fair and exciting competition. Find out more about them <Link to="/judges">here</Link>.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingTwo">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Who Can Enter?
                            </button>
                        </h3>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                Anyone with some experience or training in truffle hunting is welcome to enter.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingThree">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                What are the Rules?
                            </button>
                        </h3>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
                        <div className="accordion-body">
                            Dogs are judged based on the number of truffle baits they find, their behavior, and the time taken compared to other competitors. Points can be deducted for undesirable behaviors such as lack of control, persistent digging, and marking territory.
                            For full competition rules, click <Link to="/competition-rules" target="_blank" rel="noopener noreferrer">here</Link> to see the latest competition rules or <Link to="/junior-competition-rules" target="_blank" rel="noopener noreferrer">here</Link> for the junior competition rules.
                        </div>

                        </div>
                    </div>
                    
                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingFour">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Why Take Part?
                            </button>
                        </h3>
                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                This unique truffle hound competition is designed to celebrate the skills and partnership between truffle hunters and their dogs. It offers participants the chance to gain valuable industry recognition, judged by an esteemed panel of experts, which can further their truffle hunting ambitions.
                                <br />
                                Competing or spectating offers a chance to see various breeds in action and connect with like-minded enthusiasts. It's a celebration of the truffle hunting community, making it less solitary and more collaborative.
                                <br />
                                Join us to be part of a vibrant and supportive community. Competing is not just about winning but about learning, sharing, and growing together.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingFive">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Junior Competition
                            </button>
                        </h3>
                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                This year we'll also be running a Juniors competition for age ranges 7-17, check out the Juniors rules <Link to="/junior-competition-rules" target="_blank" rel="noopener noreferrer">here</Link>.
                                <br />
                                {/* add to cart/ quantity selector for junior competition tickets */}
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingSix">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                What are the Prizes?
                            </button>
                        </h3>
                        <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                <strong>Gold First Place:</strong> Certificate recognized within the industry, a rosette, score sheet, truffle products from Truffle Hunter, and a mushroom foray with Melissa for 2 people.
                                <br />
                                <strong>Silver First Place:</strong> Certificate, rosette, score sheet, truffle products from Truffle Hunter, and a mushroom foray with Melissa for 1 person.
                                <br />
                                <strong>Bronze First Place:</strong> Certificate, rosette, score sheet, a bottle of truffle oil from Truffle Hunter, and a mushroom foray with Melissa for 1 person.
                                <br />
                                <strong>Second Place in All Categories:</strong> Certificate, rosette, score sheet, and truffle products from Truffle Hunter.
                                <br />
                                <strong>Third Place in All Categories:</strong> Certificate, rosette, score sheet, and a bottle of truffle oil from Truffle Hunter.
                                <br />
                                All winners will be announced on the website and recognized for their achievements unless they object. Remaining competitors receive a packet of Truffle Hunter Crisps, a certificate, and their score sheet.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingSeven">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                Using Truffle Oil to Train Your Hound
                            </button>
                        </h3>
                        <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                Truffle Hunter has been a generous sponsor for three years. Their truffle oil is recommended for both culinary use and dog training due to its effectiveness in mimicking the aromatic compounds found in truffles.
                                <br />
                                Check out their website <a href="https://trufflehunter.co.uk">here</a>.
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingEight">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                How Can I Enter?
                            </button>
                        </h3>
                        <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                            Purchase your competitor ticket and complete the two forms we’ll send you. Hey presto - you’ve entered!
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <h3 className="accordion-header" id="headingNine">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                Which Level is Right for Us?
                            </button>
                        </h3>
                        <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                We determine which category you should be in as follows: Firstly, read the competition rules and judge for yourself how realistic it is that your dog will be able to find the hides of different types above ground/ below or both. After you’ve bought your ticket, we'll ask you to fill out two forms. One is a small awareness test on sustainable truffle hunting and the other is designed to help us place you. We will let you know our decision based on your confidence level and the answers you provide to our questionnaire.
                                <ul>
                                    <li>Bronze for beginners-intermediate</li>
                                    <li>Silver for intermediates-advanced</li>
                                    <li>Gold for advanced-pro</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default CompetitionFAQs;
