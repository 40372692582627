// src/userPortal/pages/UserPortal.js
import React, { useState } from 'react';
import { useAuthContext } from '../../context/AuthContext';
import userTypeConfig from '../userTypeConfig';

const UserPortal = () => {
  const { user, userType } = useAuthContext();
  const [showSections, setShowSections] = useState({});

  const toggleSection = (sectionName) => {
    setShowSections((prev) => ({
      ...prev,
      [sectionName]: !prev[sectionName],
    }));
  };

  if (!userType) return <p>Loading...</p>;

  const sections = userTypeConfig[userType] || [];

  return (
    <div className="my-festival">
      <h1>Welcome, {user.name}</h1>
      <p>User Type: {userType}</p> {/* Display the user type here */}

      {sections.map((section, index) => (
        <div key={index} className="section">
          <h2 onClick={() => toggleSection(section.name)} className="toggle-header">
            {section.name} {showSections[section.name] ? '-' : '+'}
          </h2>
          {showSections[section.name] && section.component && (
            <div className="section-content">
              <section.component />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default UserPortal;
