// src/context/CartContext.js

import React, { createContext, useState, useEffect } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState(() => {
        // Initialize state from localStorage only if cartItems is empty
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        return storedCartItems;
    });

    // Update localStorage whenever cartItems changes
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);

    const addItemToCart = (product) => {
        console.log("adding item to cart: ", product);
        setCartItems(prevItems => {
            const existingItem = prevItems.find(item => item.id === product.id);
            let updatedItems;
            if (existingItem) {
                updatedItems = prevItems.map(item =>
                    item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
                );
            } else {
                updatedItems = [...prevItems, { ...product, quantity: 1 }];
            }
            return updatedItems;
        });
    };

    const increaseQuantity = (productId) => {
        setCartItems(prevItems => {
            const updatedItems = prevItems.map(item =>
                item.id === productId ? { ...item, quantity: item.quantity + 1 } : item
            );
            return updatedItems;
        });
    };

    const decreaseQuantity = (productId) => {
        setCartItems(prevItems => {
            const updatedItems = prevItems.map(item =>
                item.id === productId ? { ...item, quantity: item.quantity - 1 } : item
            ).filter(item => item.quantity > 0);
            return updatedItems;
        });
    };

    const getCartCount = () => {
        return cartItems.reduce((count, item) => count + item.quantity, 0);
    };

    return (
        <CartContext.Provider value={{ cartItems, addItemToCart, increaseQuantity, decreaseQuantity, getCartCount }}>
            {children}
        </CartContext.Provider>
    );
};
