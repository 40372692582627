import React, { useEffect, useState } from 'react';
import '../styles/components/countdownTimer.css';

const CountdownTimer = ({ targetDate }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const distance = targetDate - now;

      if (distance > 0) {
        const days = String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(2, '0');
        const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');

        setTimeRemaining({ days, hours, minutes, seconds });
      }
    };

    const timerInterval = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timerInterval); // Clear interval on component unmount
  }, [targetDate]);

  const renderTimeBlock = (label, value) => (
    <div className="bloc-time" data-init-value={value}>
      <span className="count-title">{label}</span>
      <div className="figure">
        <span className="bottom">{value.charAt(0)}</span>
        <span className="bottom">{value.charAt(1)}</span>
      </div>
    </div>
  );

  return (
    <div className="countdown">
      {renderTimeBlock("Days", timeRemaining.days)}
      {renderTimeBlock("Hours", timeRemaining.hours)}
      {renderTimeBlock("Minutes", timeRemaining.minutes)}
      {renderTimeBlock("Seconds", timeRemaining.seconds)}
    </div>
  );
};

export default CountdownTimer;
