// src/pages/Market.js


import React from 'react';
import '../styles/pages/market.css';
import { marketData } from '../data/marketData';  
import PeopleList from '../components/PeopleList'; 

const Market = () => {
    return (
        <div className="market-section">
            <h2 className="market-title">Market Stalls</h2>
            <div className='content-wrapper'>
                <p>
                Step into The Great British Truffle Festival's Indoor Market, where the essence of truffle culture meets the creativity and craftsmanship of local artisans, food producers, and truffle hunters. Our market is a curated space that brings together a vibrant community of creators who share our passion for sustainability, quality, and the natural world. Here, you'll find everything from artisanal goods and handcrafted truffle tools to gourmet products and truffle-inspired art. Each stallholder has been carefully selected to offer you unique and high-quality items that celebrate the intersection of tradition and innovation, all while supporting sustainable practices and local craftsmanship.
                </p>
            </div>
            <PeopleList data={marketData} />
        </div>
    );
};

export default Market;
