// src/sections/CompetitionWinners.js
import React, { useState } from 'react';
import '../styles/sections/competitionWinners.css';

import goldFirst from '../assets/images/winners/Jon _ First Gold.jpg'; 
import goldSecond from '../assets/images/winners/2nd Julie Heslop and Flori Julie.jpeg'; 
import goldThird from '../assets/images/winners/Cresta and Henry.jpg'; 

import silverFirst from '../assets/images/winners/silver_first_Steve and Mac.png'; 
import silverSecond from '../assets/images/winners/silver_second_Beverly and Enzo.png'; 
import silverThird from '../assets/images/winners/silver_third_Nina and Oscar.png'; 

import bronzeFirst from '../assets/images/winners/bronze_first_Dan and Winnie.jpg'; 
import bronzeSecond from '../assets/images/winners/bronze_second_Susan and Kara.jpg'; 
import bronzeThird from '../assets/images/winners/bronze_third_Emily and Penny.png'; 

import firstTrophy from '../assets/images/winners/first.png';
import secondTrophy from '../assets/images/winners/second.png';
import thirdTrophy from '../assets/images/winners/third.png';

const CompetitionWinners = () => {
    const [modalImage, setModalImage] = useState(null);

    const openModal = (image) => {
        setModalImage(image);
    };

    const closeModal = () => {
        setModalImage(null);
    };

    const renderImages = (images) => {
        return images.map((image, index) => (
            <div className="col-md-4 col-sm-6 mb-3 card" key={index} onClick={() => openModal(image.src)}>
                <img src={image.src} className="img-fluid" alt={image.alt} />
                <div className="details">
                    <span className="winner-name">{image.alt}</span>
                    <img src={image.trophy} className="trophy" alt={`${image.alt} Trophy`} />
                </div>
            </div>
        ));
    };

    const goldWinners = [
        { src: goldFirst, alt: "Jon", trophy: firstTrophy },
        { src: goldSecond, alt: "Julie and Flori", trophy: secondTrophy },
        { src: goldThird, alt: "Cresta and Henry", trophy: thirdTrophy },
    ];

    const silverWinners = [
        { src: silverFirst, alt: "Steve and Mac", trophy: firstTrophy },
        { src: silverSecond, alt: "Beverly and Enzo", trophy: secondTrophy },
        { src: silverThird, alt: "Nina and Oscar", trophy: thirdTrophy },
    ];

    const bronzeWinners = [
        { src: bronzeFirst, alt: "Dan and Winnie", trophy: firstTrophy },
        { src: bronzeSecond, alt: "Susan and Kara", trophy: secondTrophy },
        { src: bronzeThird, alt: "Emily and Penny", trophy: thirdTrophy },
    ];

    return (
        <section className="winners-section py-5">
            <div className="container">
                <h2 className="text-center mb-4">Last Years Winners</h2>

                <h3 className="text-center mb-4 gold">Gold</h3>
                <div className="row">
                    {renderImages(goldWinners)}
                </div>

                <h3 className="text-center mb-4 silver">Silver</h3>
                <div className="row">
                    {renderImages(silverWinners)}
                </div>

                <h3 className="text-center mb-4 bronze">Bronze</h3>
                <div className="row">
                    {renderImages(bronzeWinners)}
                </div>
            </div>

            {modalImage && (
                <div className="modal" onClick={closeModal}>
                    <span className="close" onClick={closeModal}>&times;</span>
                    <img className="modal-content" src={modalImage} alt="Winner" />
                </div>
            )}
        </section>
    );
};

export default CompetitionWinners;
