import React from 'react';
import '../styles/pages/judges.css'; 

import stefRouletImage from '../assets/images/stefRoulet.png';
import hannahGreenoImage from '../assets/images/hannahGreeno.png';
import julesClaphamImage from '../assets/images/julesClapham.png';
import helenByrneImage from '../assets/images/helenByrne.png';

const judges = [
    {
        name: 'Stef Roulet',
        image: stefRouletImage,
        description: 'Stef Roulet has a diverse background in dog training and canine nutrition. Her journey in scent detection began with her mixed-breed dog, but her passion deepened when she trained her Alaskan Klee Kai to identify gluten and gluten contamination in food, drinks, medicine, and cosmetics to assist her disabled coeliac sister. Stef is the first and only person in the UK to have a qualified Gluten Detection Team. Stef served on the board of <a href="https://www.odorservicedogs.org/" target="_blank" rel="noopener noreferrer">ODOR Service Dogs</a> and co-founded <a href="https://www.detectiondogtrials.com/" target="_blank" rel="noopener noreferrer">Detection Dog Trials</a>, where she designed and ran successful competitions for many years. She also played a crucial role in launching <a href="https://scentops.co.uk" target="_blank" rel="noopener noreferrer">Scent Ops</a>, one of the UK\'s most successful scentwork trials. She has trained dogs on various scents, including sports odours, nuts, migraines, tobacco, and cadaver. Stef sought a new challenge for her dogs and chose truffle hunting. She values the ecological aspects of truffle hunting and has successfully trained a team of three Alaskan Klee Kai, proving that non-traditional breeds can excel in this field.'
    },
    {
        name: 'Hannah Greeno',
        image: hannahGreenoImage,
        description: 'Hannah Greeno is a professional dog trainer and behaviourist who has operated her business, <a href="https://www.canineneeds.com/" target="_blank" rel="noopener noreferrer">Canine Needs</a>, for 14 years, catering to the pets of the rich and famous. She has exhibited at the Kennel Club Discover Dogs show, judged SuperDogs Live at the National Pet Show and The Truffle Festival, and appeared as a TV expert on Channel 4 and Sky News Live. Hannah\'s scent training journey started 13 years ago with cadaver dog training for rescues. In her business, she introduced scent detection for lost items like keys and slippers to keep dogs engaged and out of rescue centres. Her personal journey into truffle hunting began with her Beauceron, who loves finding truffles. Since her first truffle hunt 8 years ago, she has been captivated by the teamwork and communication between humans and dogs in this unique activity. Hannah says, "The Great British Truffle Festival for me is a celebration of our human connection to the natural world, what can be achieved when species come together and seek the messages of nature. Seeing so many people come together to celebrate their shared appreciation of the Truffle was inspirational and left me with a renewed sense of community and restored faith in all that is good and natural."'
    },
    {
        name: 'Jules Clapham',
        image: julesClaphamImage,
        description: 'Jules Clapham has an equestrian background, having worked for high-level event riders and running her own yard for breaking and retraining horses. She also taught gundog classes, agility, and pet obedience. Jules has competed in agility at KC G7 and UKA Championships and is one of only six <a href="https://www.thekennelclub.org.uk/dog-training/kennel-club-accredited-instructors/list-of-accredited-instructors" target="_blank" rel="noopener noreferrer">Accredited Instructors with the Kennel Club</a> for competitive agility. Seeking a new activity for her retired agility dogs, she turned to scentwork. Jules is a <a href="https://scentworkuk.com/" target="_blank" rel="noopener noreferrer">Scentwork UK</a> Level 4 Instructor and a judge for Scentwork UK and <a href="https://www.detectiondogtrials.com/" target="_blank" rel="noopener noreferrer">Detection Dog Trials</a>. She has also started her own scentwork trials, <a href="https://scentops.co.uk" target="_blank" rel="noopener noreferrer">ScentOps</a>, which are gaining popularity in the UK. Jules regularly teaches classes and workshops for dogs at all levels, and her team, <a href="https://www.instagram.com/team_truffle_hunter" target="_blank" rel="noopener noreferrer">team_truffle_hunter</a>, consisting of two collies and a spaniel, successfully hunts wild truffles.'
    },
    {
        name: 'Helen Byrne',
        image: helenByrneImage,
        description: 'Helen Byrne is a Certified Gold Trick Dog Instructor and Trainer of the Year with Kyra Sundance’s Do More With Your Dog since 2020. In 2022, she completed The College of Scent Dogs, City & Guilds Assured Programme, gaining her CSD Scent Detection Dog Handler & Scent Detection Instructor credentials. Helen is a proud CSD Ambassador and an accredited Trials Manager & Judge for SWUK, a judge for Scent Ops, and a <a href="https://noseworkgames.org/" target="_blank" rel="noopener noreferrer">Nosework Games</a> Organiser. Helen and her teammate Nellie have achieved great success in many sports scentwork organizations, competing at the highest levels and winning numerous championships. They are currently competing at Level 9, the highest working level in <a href="https://scentworkuk.com/" target="_blank" rel="noopener noreferrer">Scentwork UK</a>. Helen and Nellie are one of only two teams nationwide to achieve Excellence in SWUK levels 1-8. Helen has long been passionate about training, earning her Certificate in Training Practice in 2004 and managing teams of trainers within prestigious national newspapers and well-known magazines. Combining her training skills, knowledge, and passion for scentwork and tricks, Helen founded <a href="https://k9scentandtricks.com" target="_blank" rel="noopener noreferrer">K9 Scent and Tricks School</a> in 2022. She and Nellie have since been captivated by truffle hunting and continue to learn about all things truffle-related. Helen is delighted to be part of the judging team for the second year, wishing all competitors fun and luck on the day.'
    }
];

const Judges = () => {
    return (
        <div>
            <section className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4">Meet Our Judges</h2>
                    <div className="row">
                        {judges.map((judge, index) => (
                            <div key={index} className={`col-12 mb-4 d-flex flex-column flex-md-row ${index % 2 === 0 ? 'flex-md-row-reverse' : ''}`}>
                                <div className="col-md-6">
                                    <img src={judge.image} className="img-fluid w-100 rounded" alt={judge.name} />
                                </div>
                                <div className="col-md-6 d-flex flex-column justify-content-center p-4">
                                    <h5 className="mb-3">{judge.name}</h5>
                                    <p className="mb-3" dangerouslySetInnerHTML={{ __html: judge.description }}></p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Judges;
