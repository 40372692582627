// src/pages/Exhibitors.js
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/theme.css';
import '../styles/components/header.css';
import '../styles/components/footer.css';
import '../styles/pages/exhibitors.css';
import Image from '../assets/images/fine_pan.webp';

const exhibitorsData = [
    {
        name: 'Truffle Masters',
        image: '/images/exhibitor1.jpg',
        description: 'Specializing in premium truffles for sale directly from the forest.',
        categories: ['Truffles for Sale', 'Local'],
        website: 'https://trufflemasters.com',
        twitter: 'https://twitter.com/trufflemasters',
        instagram: 'https://instagram.com/trufflemasters',
        blog: 'https://blog.thegreatbritishtrufflefestival.co.uk/truffle-masters'
    },
    {
        name: 'Gourmet Tools',
        image: '../assets/images/fine_pan.webp',
        description: 'Offering a range of tools for truffle hunting and gourmet cooking.',
        categories: ['Truffle Tools'],
        website: 'https://gourmettools.com',
        facebook: 'https://facebook.com/gourmettools',
        linkedin: 'https://linkedin.com/in/gourmettools',
        blog: 'https://blog.thegreatbritishtrufflefestival.co.uk/gourmet-tools'
    },
    {
        name: 'Local Delights',
        image: '/images/exhibitor3.jpg',
        description: 'Providing locally sourced truffle products and gourmet delights.',
        categories: ['Local', 'Truffles for Sale'],
        website: 'https://localdelights.com',
        twitter: 'https://twitter.com/localdelights',
        instagram: 'https://instagram.com/localdelights',
        blog: 'https://blog.thegreatbritishtrufflefestival.co.uk/local-delights'
    },
    {
        name: 'Truffle Hound Gear',
        image: '/images/exhibitor4.jpg',
        description: 'Your one-stop shop for truffle hound training tools and accessories.',
        categories: ['Truffle Hound Training Tools'],
        website: 'https://trufflehoundgear.com',
        facebook: 'https://facebook.com/trufflehoundgear',
        linkedin: 'https://linkedin.com/in/trufflehoundgear',
        blog: 'https://blog.thegreatbritishtrufflefestival.co.uk/truffle-hound-gear'
    }
];

const filterCategories = [
    'Truffles for Sale',
    'Truffle Tools',
    'Local',
    'Truffle Hound Training Tools'
];

const Exhibitors = () => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [filteredExhibitors, setFilteredExhibitors] = useState(exhibitorsData);

    useEffect(() => {
        if (selectedCategories.length === 0) {
            setFilteredExhibitors(exhibitorsData);
        } else {
            const filtered = exhibitorsData.filter(exhibitor =>
                selectedCategories.every(category => exhibitor.categories.includes(category))
            );
            setFilteredExhibitors(filtered);
        }
    }, [selectedCategories]);

    const toggleCategory = (category) => {
        setSelectedCategories((prevCategories) =>
            prevCategories.includes(category)
                ? prevCategories.filter((cat) => cat !== category)
                : [...prevCategories, category]
        );
    };

    return (
        <div>
            <Header />
            <section className="hero-section" role="banner">
                <div className="container text-center">
                    <h1>Indoor Market</h1>
                    <p>Explore our diverse range of exhibitors and discover their unique offerings in the indoor market.</p>
                </div>
            </section>

            <section className="filter-section py-3">
                <div className="container text-center">
                    <h3>Filter Stall Holders</h3>
                    <div className="filter-chips">
                        {filterCategories.map((category) => (
                            <button
                                key={category}
                                className={`chip ${selectedCategories.includes(category) ? 'selected' : ''}`}
                                onClick={() => toggleCategory(category)}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>
            </section>

            <section className="exhibitors-section py-5">
                <div className="container">
                    <div className="row">
                        {filteredExhibitors.length > 0 ? (
                            filteredExhibitors.map((exhibitor, index) => (
                                <div key={index} className="col-md-6 mb-4">
                                    <div className="card exhibitor-card">
                                        <img src={Image} className="card-img-top" alt={exhibitor.name} />
                                        <div className="card-body">
                                            <h5 className="card-title">{exhibitor.name}</h5>
                                            <p className="card-text">{exhibitor.description}</p>
                                            <div className="social-links mb-3">
                                                {exhibitor.website && <a href={exhibitor.website} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fas fa-globe"></i></a>}
                                                {exhibitor.twitter && <a href={exhibitor.twitter} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-twitter"></i></a>}
                                                {exhibitor.instagram && <a href={exhibitor.instagram} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-instagram"></i></a>}
                                                {exhibitor.facebook && <a href={exhibitor.facebook} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-facebook"></i></a>}
                                                {exhibitor.linkedin && <a href={exhibitor.linkedin} target="_blank" rel="noopener noreferrer" className="btn btn-primary m-1"><i className="fab fa-linkedin"></i></a>}
                                            </div>
                                            <a href={exhibitor.blog} target="_blank" rel="noopener noreferrer" className="btn btn-secondary">Read More on Our Blog</a>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-center">No exhibitors match your selected categories.</p>
                        )}
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Exhibitors;
