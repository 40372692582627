// src/components/LinksSlider.js

import React from 'react';
import '../styles/components/linksSlider.css';

const LinksSlider = ({ links }) => {
    return (
        <div className="links-slider">
            {links.map((link, index) => (
                <div key={index} className="link-item">
                    <img src={link.image} alt={link.title} className="link-image" />
                    <div className="link-details">
                        <a href={link.source} target="_blank" rel="noopener noreferrer" className="link-title">
                            {link.title}
                        </a>
                        <p className="link-source">{link.sourceName}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LinksSlider;
