//src/lib/Appwrite.js

import { Client, Account } from 'appwrite';
import axios from 'axios';

const appwriteEndpoint = process.env.REACT_APP_APPWRITE_ENDPOINT;
const appwriteProjectId = process.env.REACT_APP_APPWRITE_PROJECT_ID;

const client = new Client()
    .setEndpoint(appwriteEndpoint)
    .setProject(appwriteProjectId);

// we handle all appwrite functions which use "account" in the front end here, and all other functions (debate/ users) in the backend
const account = new Account(client);

// get the current session
const getCurrentSession = async () => {
    try {
        return await account.getSession('current');
    } catch (error) {
        console.log('Failed to fetch current session');
        return null;
    }
};

// get the account which is currently logged in
const getCurrentAccount = async () => {
    try {
        return await account.get();
    } catch (error) {
        console.error('Failed to fetch current user (Appwrite.js):', error);
        return null;
    }
};


// log in an account
const loginAccount = async (email, password) => {
    try {
        console.log("Logging in user:", email);
        const session = await account.createEmailPasswordSession(email, password);
        console.log('Logged in user:', email);
        return session;
    } catch (error) {
        console.error('Failed to log in user:', error);
        throw error;
    }
};

// log out an account
const logoutAccount = async () => {
    try {
        await account.deleteSession('current');
    } catch (error) {
        console.error('Failed to log out user:', error);
        throw error;
    }
};

// update password
const updateAccount = async (accountId, newPassword) => {
    try {
        // Fetch the existing account details to get the email
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/gbtf-account/${accountId}`);
        const existingAccount = response.data;

        if (!existingAccount) {
            throw new Error(`Account with ID ${accountId} not found.`);
        }

        // Update the account password
        const updatedAccount = await account.updatePassword(newPassword);

        console.log('Account updated with new password:', updatedAccount.$id);
        return updatedAccount;
    } catch (error) {
        console.error('Failed to update account password:', error.message);
        throw error;
    }
};

// login using a custom token
const loginCustomToken = async (accountId, secret) => {
    console.log("Generating custom token for:", accountId);
    try {
        const session = await account.createSession(
            accountId,
            secret
        );

        return session;
    } catch (error) {
        console.error('Failed to log in using custom token:', error);
        throw error;
    }
};

export { client, account, loginCustomToken, updateAccount, loginAccount, logoutAccount, getCurrentSession, getCurrentAccount };
