import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/sections/ctaSection.css';

const CtaSection = ({ id, title, description, linkText, linkUrl, onClick }) => (
  <section id={id}>
    <div className="content-wrapper">
      <div className="join-us">
        <h3>{title}</h3>
        <p>{description}</p>
        {linkUrl.startsWith('/') ? (
          <Link to={linkUrl} className="btn btn-primary color-cycle waitlistButton">{linkText}</Link>
        ) : (
          <button onClick={onClick} className="btn btn-primary color-cycle waitlistButton">{linkText}</button>
        )}
      </div>
    </div>
  </section>
);

export default CtaSection;
