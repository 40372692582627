// src/components/TastingMenu.js

import React from 'react';
import QuantitySelector from './QuantitySelector';
import { useProductContext } from '../context/ProductContext';

const TastingMenu = ({ getCartItemQuantity, handleAddToCart, increaseQuantity, decreaseQuantity }) => {
    const { meatProduct } = useProductContext();

    return (
        <div className="menu-card">
            <h2>Nine Course Truffle Tasting Menu</h2>
            <br />
            <p>Truffle and Woodland Wild Mushroom High Tea</p>
            <p>-</p>
            <p>Whipped Duck Liver, Truffle, Brioche, Wood Sorrel</p>
            <p>-</p>
            <p>Truffle Hertfordshire Potato Fritters, Lincolnshire Poacher, Walnut</p>
            <p>-</p>
            <p>Red Leg Partridge, Wild Alexanders & Truffle Cassoulet</p>
            <p>(Partridge Wine Pairing Option Available)</p>
            <p>-</p>
            <p>Green Risotto Arancini, Poached Hen's Egg, Purple Kale & Three Cornered Leek </p>
            <p>-</p>
            <p>Stuffed Rolled Slow Roasted Lamb Shoulder, Truffle Butter Beans & Winter Vegetables</p>
            <p>(Lamb Wine Pairing Option Available)</p>
            <p>-</p>
            <p>Wood Sorrel Sorbet</p>
            <p>(Dessert Wine Pairing Option Available)</p>
            <p>-</p>
            <p>Truffle & Apple Beignet, Mousse</p>
            <p>-</p>
            <p>Truffle-Inspired Petit Fours</p>
            <p>(Digestif Option Available)</p>

            {/* Meat Qty Selector */}
            {meatProduct && (
                <div className="menu-row">
                    <div className="menu-item-name">Regular (£120)</div>
                    <div className="quantity-selector-wrapper">
                        <QuantitySelector
                            product={meatProduct}
                            quantity={getCartItemQuantity(meatProduct.id)}
                            onAddToCart={handleAddToCart}
                            onIncrease={increaseQuantity}
                            onDecrease={decreaseQuantity}
                        />
                    </div>
                </div>
            )}

        </div>
    );
};

export default TastingMenu;
