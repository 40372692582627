// src/components/TastingMenu.js

import React from 'react';
import QuantitySelector from './QuantitySelector';
import { useProductContext } from '../context/ProductContext';

const VegTastingMenu = ({ getCartItemQuantity, handleAddToCart, increaseQuantity, decreaseQuantity }) => {
    const { vegProduct } = useProductContext();

    return (
        <div className="menu-card">
            <h2>Nine Course Vegetarian Truffle Tasting Menu</h2>
            <br />
            <p>Truffle and Woodland Wild Mushroom High Tea</p>
            <p>-</p>
            <p>Sussex Farm Crème Fraîche, Truffle, Brioche, Wood Sorrel</p>
            <p>-</p>
            <p>Truffle Hertfordshire Potato Fritters, Lincolnshire Poacher, Walnut</p>
            <p>-</p>
            <p>Pickled Walnut, Wild Alexanders & Truffle Cassoulet</p>
            <p>-</p>
            <p>Green Risotto Arancini, Poached Hen's Egg, Purple Kale & Three Cornered Leek </p>
            <p>-</p>
            <p>Wild Porcini Vol-Au-Vent, Truffle Butter Beans & Winter Vegetables</p>
            <p>-</p>
            <p>Wood Sorrel Sorbet</p>
            <p>(Dessert Wine Pairing Option Available)</p>
            <p>-</p>
            <p>Truffle & Apple Beignet, Mousse</p>
            <p>-</p>
            <p>Truffle-Inspired Petit Fours</p>
            <p>(Digestif Option Available)</p>

            
            {/* Veggie Qty Selector */}
            {vegProduct && (
                <div className="menu-row">
                    <div className="menu-item-name">Vegetarian (£100)</div>
                    <div className="quantity-selector-wrapper">
                        <QuantitySelector
                            product={vegProduct}
                            quantity={getCartItemQuantity(vegProduct.id)}
                            onAddToCart={handleAddToCart}
                            onIncrease={increaseQuantity}
                            onDecrease={decreaseQuantity}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default VegTastingMenu;
