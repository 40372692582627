// src/pages/Success.js

import React from 'react';
import '../styles/pages/success.css'; 

function Success() {
    

    return (
        <div className="success-container">
            <div className="success-content">
                <h1 className="success-heading">Payment Successful!</h1>
                    <div>
                        <p className="success-message">You should receive your receipt automatically via email which will be your proof of purchase on the door.</p>
                        <p className="success-message">Thank you - can't wait to see you on the day!  </p>
                    </div>
            </div>
        </div>
    );
}

export default Success;
