// src/pages/Tickets.js

import React from 'react';

import '../styles/pages/tickets.css';

import ProductList from '../components/ProductList';

const Tickets = () => {
    return (
        <div className="tickets-container">
            <div className="container mt-4">
                <h2 className="text-center">Tickets</h2>
                <ProductList initialCategory="Tickets" />
            </div>
        </div>
    );
};

export default Tickets;
