// src/sections/AboutSection.js


import React from 'react';
import '../styles/sections/aboutSection.css'

const AboutSection = () => (
    <section id="about">
        <div className="content-wrapper">
            <h2>The Festival</h2>
            <p>The Great British Truffle Festival is a unique celebration that brings together truffle enthusiasts from all walks of life, including novices, experts, dog lovers, foodies, and nature enthusiasts. Our festival is dedicated to the exploration and appreciation of Britain's unique truffle ecosystem, offering a wide range of activities from engaging talks, workshops and cooking demos to have-a-go truffle hound training with your dog - as well as, of course, The Truffle Hound Competition! The Great Britsh Truffle Festival is a place where the passion for truffles and the spirit of discovery unite, fostering a vibrant community eager to learn, connect, eat great food and celebrate the art and science of truffling in a fun, sustainable and inclusive way.</p>
        </div>
    </section>
);

export default AboutSection;
