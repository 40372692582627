//src/userPortal/pages/competitors
import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Registration = ({ isEditing, setIsEditing }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    handlerName: '',
    dogName: '',
    dogBreed: '',
    dogAge: '',
    dogSex: '',
    training: '',
    experience: '',
    skillLevel: '',
    competitionGroup: '',
    agreeTerms: false,
    acknowledged: '',
  });

  useEffect(() => {
    if (isEditing) {
      // Fetch and set existing registration data here if editing
      // Example:
      // const existingData = fetchUserDataFromDatabase(user.$id);
      // setFormData(existingData);
    }
  }, [isEditing, user]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted', formData);
    // Here you can add your form submission logic, e.g., sending data to your server
    setIsEditing(false);
    navigate('/thank-you');
  };

  return (
    <div className="registration-page">
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">
            The Great British Truffle Hound Competition {isEditing ? 'Edit' : 'Registration'}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="fullName" className="form-label">Full Name</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" className="form-label">Mobile Telephone Number</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="handlerName" className="form-label">Handler's Name (if different)</label>
              <input
                type="text"
                className="form-control"
                id="handlerName"
                name="handlerName"
                value={formData.handlerName}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogName" className="form-label">Dog's Name</label>
              <input
                type="text"
                className="form-control"
                id="dogName"
                name="dogName"
                value={formData.dogName}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogBreed" className="form-label">Dog's Breed</label>
              <input
                type="text"
                className="form-control"
                id="dogBreed"
                name="dogBreed"
                value={formData.dogBreed}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogAge" className="form-label">Dog's Age</label>
              <input
                type="number"
                className="form-control"
                id="dogAge"
                name="dogAge"
                value={formData.dogAge}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="dogSex" className="form-label">Dog's Sex</label>
              <select
                className="form-control"
                id="dogSex"
                name="dogSex"
                value={formData.dogSex}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="training" className="form-label">Professional Truffle Hound Training</label>
              <select
                className="form-control"
                id="training"
                name="training"
                value={formData.training}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="experience" className="form-label">Previous Truffle Hunting Experience</label>
              <select
                className="form-control"
                id="experience"
                name="experience"
                value={formData.experience}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="skillLevel" className="form-label">Truffle Scent Finding Skills (1-10)</label>
              <input
                type="number"
                className="form-control"
                id="skillLevel"
                name="skillLevel"
                value={formData.skillLevel}
                onChange={handleChange}
                min="1"
                max="10"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="competitionGroup" className="form-label">Preferred Competition Group</label>
              <select
                className="form-control"
                id="competitionGroup"
                name="competitionGroup"
                value={formData.competitionGroup}
                onChange={handleChange}
                required
              >
                <option value="">Select...</option>
                <option value="bronze">Bronze</option>
                <option value="silver">Silver</option>
                <option value="gold">Gold</option>
              </select>
            </div>
            <div className="mb-3 form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="agreeTerms"
                name="agreeTerms"
                checked={formData.agreeTerms}
                onChange={handleChange}
                required
              />
              <label className="form-check-label" htmlFor="agreeTerms">
                I agree to the competition terms and conditions.
              </label>
            </div>
            <div className="mb-3">
              <label htmlFor="acknowledged" className="form-label">Acknowledgement</label>
              <input
                type="text"
                className="form-control"
                id="acknowledged"
                name="acknowledged"
                value={formData.acknowledged}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">{isEditing ? 'Save' : 'Register'}</button>
          </form>
          <div className="mt-4">
            <p>Early Bird Truffle Hound Competition tickets have just been launched and are now available by clicking the link below. The early bird price is £40, a saving of £15. Full price competitor ticket is £55. These tickets are limited and only available for a month from today; offer ends 03/07/24 at midnight.</p>
            <a href="https://buy.stripe.com/9AQ8wO6ap6R2doc28a" className="btn btn-secondary">Buy Tickets</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Registration;
