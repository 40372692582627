import React from 'react';
import { Link } from 'react-router-dom';
import CountdownTimer from '../components/CountdownTimer';
import '../styles/sections/heroSection.css';

const HeroSection = ({ title, description, image, showButton = false, buttonText, buttonTarget, isHalfHeight = false, showCountdown = false, countdownDate }) => {
  const isExternalUrl = buttonTarget && (buttonTarget.startsWith('http') || buttonTarget.startsWith('https'));

  return (
    <section className={`hero-section ${isHalfHeight ? 'half-height' : ''}`} role="banner">
      <div className="hero-content">
        {image && <img src={image} alt="Hero" className="hero-image" />}
        <div className="hero-text">
          <h1>{title}</h1>
          {showCountdown && countdownDate && (
            <CountdownTimer targetDate={countdownDate} />
          )}
          <h4>{description}</h4>
          {showButton && buttonText && buttonTarget && (
            isExternalUrl ? (
              <a href={buttonTarget} className="btn btn-primary color-cycle subscriptionButton" target="_blank" rel="noopener noreferrer">
                {buttonText}
              </a>
            ) : (
              <Link to={buttonTarget} className="btn btn-primary color-cycle subscriptionButton">
                {buttonText}
              </Link>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
