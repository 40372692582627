// src/data/marketData.js

    // Common images
    import facebookImage from '../assets/images/market/facebookImage.png';
    import etsyImage from '../assets/images/market/etsyImage.png';
    import instagramImage from '../assets/images/market/instagramImage.png';
    import twitterImage from '../assets/images/market/twitterImage.png';
    import youTubeImage from '../assets/images/market/youTubeImage.png'

    // Unique images
    import nickyFoy from '../assets/images/market/rogerPhillips.png';
    import wildFlowersLinktreeImage from '../assets/images/market/nickyFoy.png';
    import rogerPhillipsObituaryImage from '../assets/images/market/justRoger.png';

    import blackGold from '../assets/images/market/blackGold.jpg';
    import blackGoldWebsiteImage from '../assets/images/market/blackGoldWebsite.jpg';
    import blackGoldTogatherImage from '../assets/images/market/blackGoldTogather.jpg';

    import slake from '../assets/images/market/slake.jpg';
    import slakeSite from '../assets/images/market/slakeSite.png';

    import sulisTruffleHunterImage from '../assets/images/market/sulisTruffleHunter.jpg';

    import loopLoopImage from '../assets/images/market/loopLoopWebsite.jpg';
    import loopLoopWebsiteImage from '../assets/images/market/loopLoopImage.png';
    
    import bestensBreweryImage from '../assets/images/market/bestensBrewerySiteImage.png';
    import bestensBrewerySiteImage from '../assets/images/market/bestensBreweryImage.jpg';

    import tailEndArtImage from '../assets/images/market/tailEndArt.jpg';
    import tailEndArtWebsiteImage from '../assets/images/market/tailEndArtWebsite.png';

    import earthBotanicalsWebsiteImage from '../assets/images/market/earthBotanicalsWebsite.png';
    import earthBotanicalsImage from '../assets/images/market/earthBotanicals.png';

export const marketData = [
    {
        name: "Roger Phillips’ Books",
        role: ['Stall Holder'],
        description: "Book seller - Roger Phillips’ Wife, Nicky Foy",
        bio: `We are honoured to welcome Nicky Foy, the wife of the late Roger Phillips MBE, an acclaimed author and pioneer in the field of botanical photography. Roger’s extensive body of work, spanning over 40 photographic plant books, has left an indelible mark on the study and appreciation of nature, from Wild Flowers to Trees, Mushrooms, and more. 

        Nicky, who was Roger's collaborator for over 50 years, has continued his legacy by completing his final projects, ensuring that his work remains accessible to new generations of nature enthusiasts. At the festival, Nicky will be offering Roger’s books, a profound celebration of the natural world which aligns perfectly with our mission to explore and appreciate the unique ecosystems that truffle hunting and the natural world offer. We’re delighted to have Nicky join us and share the beauty of the natural world through Roger’s timeless works.`,
        images: [
            nickyFoy,
        ],
        links: [
            {
                image: instagramImage,
                title: 'Roger Phillips on Instagram',
                source: 'https://www.instagram.com/rogerphillips_redglasses/',
                sourceName: 'Instagram - @rogerphillips_redglasses',
            },
            {
                image: wildFlowersLinktreeImage,
                title: 'Wild Flowers Book Linktree',
                source: 'https://linktr.ee/WildFlowersBook',
                sourceName: 'Linktree - Wild Flowers Book',
            },
            {
                image: instagramImage,
                title: 'Roger Phillips Mushrooms on Instagram',
                source: 'https://www.instagram.com/rogerphillipsmushrooms',
                sourceName: 'Instagram - @rogerphillipsmushrooms',
            },
            {
                image: instagramImage,
                title: 'Roger Phillips Book on Instagram',
                source: 'https://www.instagram.com/p/C8kgqSCITFX/?img_index=1',
                sourceName: 'Instagram - Roger Phillips Book',
            },
            {
                image: rogerPhillipsObituaryImage,
                title: 'Roger Phillips MBE (1932 - 2021), Obituary',
                source: 'https://www.britmycolsoc.org.uk/society/obituaries/roger-phillips',
                sourceName: 'British Mycological Society',
            },
        ]
    },
    {
        name: "Hajira Ahmad's Black and Gold Experiences",
        role: ['Stall Holder'],
        description: '',
        bio: `Hajira Ahmad brings a slice of the Italian truffle experience to the heart of our festival. With her initiative, Black Gold Experiences, she has curated a unique blend of truffle hunting, gourmet dining, and immersive cultural experiences. Hajira, a passionate truffle hunter, trained her beloved dog Angelo to become an expert truffle hound, and together they embark on truffle adventures that capture the essence of Italy’s Lazio region.

        At the Great British Truffle Festival, Hajira’s offerings resonate with our values of community, sustainability, and a deep appreciation for the art of truffling. Her truffle-infused dinners and bespoke tours not only highlight the culinary excellence of truffles but also celebrate the deep bond between hunter and hound, which is at the core of our festival. We’re thrilled to have Hajira share her passion and expertise, enriching our festival with her unique approach to truffle culture.`,
        images: [
            blackGold,
        ],
        links: [
            {
                image: instagramImage,
                title: 'Black Gold Experiences on Instagram',
                source: 'https://www.instagram.com/blackgoldexperiences/',
                sourceName: 'Instagram - @blackgoldexperiences',
            },
            {
                image: blackGoldWebsiteImage,
                title: 'Black Gold Experiences Website',
                source: 'https://www.blackgoldexperiences.com',
                sourceName: 'Black Gold Experiences',
            },
            {
                image: blackGoldTogatherImage,
                title: 'Black Gold Experiences on Togather',
                source: 'https://togather.com/suppliers/black-gold-experiences',
                sourceName: 'Togather - Black Gold Experiences',
            },
        ]
    },
    {
        name: 'Slake Spirits',
        role: ['Stall Holder'],
        description: 'Distillery',
        bio: `Slake Spirits represents the perfect blend of science and nature, crafting high-quality spirits inspired by the rich diversity of the Sussex countryside. This independent distillery, lead by Dr. Thomas Martin-Wells, nestled between the South Downs and the Sea, draws from the ancient woodlands and unique heathland habitats that make Sussex a special place.
        Their meticulous approach to distillation, rooted in chemistry and a love for local ingredients, makes them a perfect partner at the festival to help in our celebration of the natural world. Slake's commitment to sustainability and local sourcing is a testament to their respect for nature, much like our own dedication to preserving the delicate ecosystems where truffles thrive. With their mythologically-inspired branding and innovative spirit, Slake Spirits brings an adventurous and exploratory energy to our festival.`,
        images: [
            slake,
        ],
        links: [
            {
                image: slakeSite,
                title: 'Slake Spirits Website',
                source: 'https://www.slakespirits.com/',
                sourceName: 'slakespirits.com',
            },
            {
                image: twitterImage,
                title: 'Slake Spirits on Twitter',
                source: 'https://x.com/SlakeSpirits',
                sourceName: '@SlakeSpirits',
            },
            {
                image: facebookImage,
                title: 'Slake Spirits on Facebook',
                source: 'https://www.facebook.com/SlakeSpirits',
                sourceName: '@SlakeSpirits',
            },
            {
                image: instagramImage,
                title: 'Slake Spirits on Insta',
                source: 'https://www.instagram.com/slakespirits/',
                sourceName: '@SlakeSpirits',
            },
            {
                image: twitterImage,
                title: 'Slake Spirits on Twitter',
                source: 'https://x.com/SlakeSpirits',
                sourceName: '@SlakeSpirits',
            },
        ]
    },
    {
        name: "Sulis Truffle Hunter",
        role: ['Stall Holder'],
        description: 'Truffle hunting tools, Truffle hunting training equipment',
        bio: `Sulis Truffle Hunter epitomises the journey of discovery that lies at the heart of truffle hunting. Starting with a simple piece of truffle-soaked cotton and a playful cocker spaniel named Winnie, this adventure has blossomed into a thriving business dedicated to helping others experience the joy of truffle hunting.

        Their training tools, inspired by Winnie's transformation into a skilled truffle hound, allow dog owners to cultivate their own truffle-hunting partnerships, fostering a deep bond and shared excitement between handler and hound. At our festival, Sulis Truffle Hunter brings not only their innovative tools but also their passion for the craft, embodying the festival’s spirit of learning, community, and the pursuit of excellence in truffle hunting.`,
        images: [
            sulisTruffleHunterImage,
        ],
        links: [
            {
                image: facebookImage,
                title: 'Sulis Truffle Hunter on Facebook',
                source: 'https://www.facebook.com/profile.php?id=61563065608796',
                sourceName: '@SulisTruffleHunter',
            },
            {
                image: etsyImage,
                title: 'Sulis Truffle Hunter on Etsy',
                source: 'https://www.etsy.com/shop/sulistrufflehunter/',
                sourceName: '@SulisTruffleHunter',
            },
        ]
    },
    {
        name: "Sophie Bresnahan's Loop Loop",
        role: ['Stall Holder'],
        description: 'Plantable Gift Cards',
        bio: `Loop Loop, founded by Sophie Bresnahan, brings the concept of sustainability to life through beautifully crafted plantable gift cards. Each card is an opportunity to grow a mini meadow, enhancing the biodiversity of our country – a theme that resonates deeply with the ethos of our festival.
        Sophie’s work is inspired by the natural world, particularly the fascinating role of mushrooms in our ecosystems, which she first discovered while living on a permaculture farm in Portugal. Her cards, made from recycled materials, are a statement of care for the environment. At The Great British Truffle Festival, Sophie’s innovative approach to art and sustainability offers our attendees a unique way to share a piece of nature and inspire a greener future.`,
        images: [
            loopLoopImage,
        ],
        links: [
            {
                image: youTubeImage,
                title: 'Loop Loop Plantable Cards on YouTube',
                source: 'https://www.youtube.com/watch?v=gmRNPZgEWSs',
                sourceName: '@LoopLoopPlantableCards',
            },
            {
                image: instagramImage,
                title: 'Loop Loop Plantable Cards on Insta',
                source: 'https://www.instagram.com/think_looploop',
                sourceName: '@Think_LoopLoop',
            },
            {
                image: loopLoopWebsiteImage,
                title: 'Loop Loop Plantable Cards Website',
                source: 'https://www.looploop.co.uk/',
                sourceName: 'looploop.co.uk',
            },
        ]
    },
    {
        name: 'Bestens Brewery',
        role: ['Stall Holder'],
        description: 'Local brewery',
        bio: `Bestens Brewery, a Sussex-based nano-brewery, is driven by the belief that beer can bring people together. Their commitment to inclusivity and community-building aligns perfectly with the values of The Great British Truffle Festival. Bestens is particularly proud of their neurodivergent team, which embodies their ethos of creating a safe and welcoming environment for all.

        Their dedication to producing the finest craft beers while fostering a strong sense of local community makes Bestens a valuable addition to our festival. They understand that, like truffle hunting, brewing is both an art and a science, and they bring that same passion for excellence to every beer they craft. We'd love to do a colab - get in touch with your ideas!`,
        images: [
            bestensBreweryImage,
        ],
        links: [
            {
                image: instagramImage,
                title: 'Bestens Brewery on Insta',
                source: 'https://www.instagram.com/bestensbrewery/',
                sourceName: '@BestensBrewery',
            },
            {
                image: facebookImage,
                title: 'Bestens Brewery on Facebook',
                source: 'https://www.facebook.com/BestensBreweryUK',
                sourceName: '@BestensBreweryUK',
            },
            {
                image: bestensBrewerySiteImage,
                title: 'Bestens Brewery Website',
                source: 'https://www.bestensbrewery.com/',
                sourceName: 'bestensbrewery.com',
            },
        ]
    },
    {
        name: "Susan Tindall's Tail End Art",
        role: ['Stall Holder'],
        description: 'Local artisan of crafts and ceramics',
        bio: `Susan Tindall of Tail End Art is a local artisan whose work is deeply rooted in the natural world. Inspired by her dog, Honey, and the beauty of the Sussex countryside, Susan creates unique ceramics and art pieces that capture the essence of nature. Her journey began with an old kiln and a passion for exploring different art forms, leading her to create pieces that resonate with the themes of our festival.
        Tail End Art’s commitment to upcycling and using natural objects in art, putting emphasis on sustainability and the celebration of nature’s beauty. Susan’s work, which includes everything from personalised commissions to large-scale public art pieces, adds a touch of local craftsmanship to our indoor market, inviting festival-goers to explore the creative possibilities that nature inspires.`,
        images: [
            tailEndArtImage,
        ],
        links: [
            {
                image: etsyImage,
                title: 'Tail End Art on Etsy',
                source: 'https://www.etsy.com/uk/shop/TailEndArt',
                sourceName: '@TailEndArt',
            },
            {
                image: instagramImage,
                title: 'Tail End Art on Insta',
                source: 'https://instagram.com/tail_end_art',
                sourceName: '@tail_end_art',
            },
            {
                image: tailEndArtWebsiteImage,
                title: 'Tail End Art Website',
                source: 'www.tailendart.com',
                sourceName: 'www.tailendart.com',
            },
            {
                image: facebookImage,
                title: 'Tail End Art on Facebook',
                source: 'https://www.facebook.com/tailendart/',
                sourceName: '@TailEndArt',
            },
        ]
    },
    {
        name: "Cleo Gabriel's Earth Botanicals",
        role: ['Stall Holder'],
        description: 'Local handmade botanical soap & sustainable homeware',
        bio: `Cleo Gabriel’s Earth Botanicals offers a harmonious blend of holistic health and creativity through handmade botanical soaps and sustainable homeware. Drawing from her background in herbal medicine and holistic healthcare, Cleo has crafted a line of products that reflect her deep respect for nature and her passion for sustainability.

        Each item from Earth Botanicals is a celebration of natural ingredients and eco-friendly practices and Cleo’s dedication to her craft, combined with her love for the natural world, ensures that everything she makes not only serves a functional purpose but also contributes to a healthier, more sustainable way of living.`,
        images: [
            earthBotanicalsImage,
        ],
        links: [
            {
                image: facebookImage,
                title: 'Earth Botanicals on Facebook',
                source: 'https://www.facebook.com/earthbotanicals/',
                sourceName: '@EarthBotanicals',
            },
            {
                image: earthBotanicalsWebsiteImage,
                title: 'Earth Botanicals Website',
                source: 'https://www.earthbotanicals.co.uk/',
                sourceName: 'earthbotanicals.co.uk',
            },
            {
                image: instagramImage,
                title: 'Earth Botanicals on Insta',
                source: 'https://www.instagram.com/earthbotanicals.uk/',
                sourceName: '@earthbotanicals.uk',
            },
            {
                image: twitterImage,
                title: 'Earth Botanicals on Twitter',
                source: 'https://www.threads.net/@earthbotanicals.uk',
                sourceName: '@earthbotanicals.uk',
            },
        ]
    },
    {
        name: "Tom Baxter's Bristol Fungarium",
        role: ['Stall Holder'],
        description: 'Organic certified medicinal mushrooms',
        bio: `Tom Baxter’s Bristol Fungarium is a testament to the power of curiosity, passion, and dedication to the natural world. In response to the growing environmental crisis, Tom made the life-changing decision in 2008 to leave behind the hustle of city life and seek purpose through working the land. After a long journey of personal exploration and deepening connection to the land, in 2019 he put down his farming tools and poured all his efforts into founding The Bristol Fungarium, the UK's first producer of organic certified medicinal mushrooms. As founder, Tom has blended his experience in organic farming with an innovative approach to fungi cultivation. His vision is rooted in sustainability and the belief that fungi can play a transformative role in human health and environmental restoration.
At The Great British Truffle Festival, Bristol Fungarium will showcase its impressive range of native and exotic medicinal mushrooms. From the lion's mane to reishi, turkey tail, and cordyceps, Tom and his team produce some of the finest organic mushrooms available in the UK. Visitors will have the opportunity to explore a selection of Bristol Fungarium’s medicinal mushroom products, which are cultivated using sustainable practices that honour the natural world. The fungi on display not only highlight Tom's commitment to quality but also to the role mushrooms can play in fostering a healthier planet.
`,
        images: [
            earthBotanicalsImage,
        ],
        links: [
            {
                image: facebookImage,
                title: 'Bristol Fungarium Website',
                source: 'https://bristolfungarium.com/',
                sourceName: 'Bristol Fungarium',
            },
            {
                image: earthBotanicalsWebsiteImage,
                title: 'Bristol Fungarium on Insta',
                source: 'https://www.instagram.com/bristolfungarium',
                sourceName: '@bristolfungarium',
            },
            {
                image: instagramImage,
                title: 'Bristol Fungarium on Facebook',
                source: 'https://www.facebook.com/thebristolfungarium/',
                sourceName: '@thebristolfungarium',
            },
            {
                image: twitterImage,
                title: 'Bristol Fungarium',
                source: 'https://www.tiktok.com/@bristolfungarium ',
                sourceName: '@bristolfungarium',
            },
            {
                image: twitterImage,
                title: 'The Bristol Fungarium at Better Food',
                source: 'https://betterfood.co.uk/supplier/bristol-fungarium/',
                sourceName: 'betterfood.co.uk',
            },
            {
                image: twitterImage,
                title: 'Bristol Fungarium at Holland and Barrett',
                source: 'https://www.hollandandbarrett.com/shop/brands/bristol-fungarium/ ',
                sourceName: 'hollandandbarrett.com',
            },
            {
                image: twitterImage,
                title: 'Bristol Fungarium Mushroom Extract Tinctures Range',
                source: 'https://www.youtube.com/watch?v=Ej53tDLmdWM',
                sourceName: '@detoxtrading, YouTube',
            },
        ]
    },
];
