// src/data/expertsData.js

// Common images
import facebookImage from '../assets/images/market/facebookImage.png';
import instagramImage from '../assets/images/market/instagramImage.png';
import twitterImage from '../assets/images/market/twitterImage.png';
import youTubeImage from '../assets/images/market/youTubeImage.png'
import tiktokImage from '../assets/images/market/tiktokImage.png'

import paulThomas from '../assets/images/paulThomas.jpg';
import paulBBC from '../assets/images/paulBBC.jpg';
import AndyPincott from '../assets/images/AndyPincott.jpg';
import melissaTruffle from '../assets/images/melissaTruffle.jpg';
import JesperLaunder from '../assets/images/JesperLaunder.jpg';
import SJ from '../assets/images/sj.png';
import Neville from '../assets/images/market/nevilleKilkenny.png'
import Alexandra from '../assets/images/market/alexandra-woods-two.jpg'


import chris1 from '../assets/images/market/chrisImg1.JPG'
import chris2 from '../assets/images/market/chrisImg2.JPG'
import chris3 from '../assets/images/market/chrisImg3.JPG'
import chris6 from '../assets/images/market/chrisImg6.JPG'
import chris10 from '../assets/images/market/chrisImg10.JPG'

export const expertsData = [
    {
        name: 'Craig Worrall',
        role: ['Workshops'],
        bio: `
At The Great British Truffle Festival, Craig will be hosting wild food cooking demonstrations throughout the day, showcasing his culinary skills and helping attendees explore the rich flavours of truffles in your home cooking. With over 15 years of experience, Craig Worrall is a master of wild food foraging, cooking, and teaching. Since 2012, Craig has shared his knowledge across the UK and abroad, inspiring countless individuals to reconnect with nature through the magic of wild flavours. Specialising in a wide range of wild foods - plants, fungi, seaweeds, shellfish, and game - Craig’s expertise goes beyond just foraging. He delves into the historical and cultural threads that tie humans to nature, promoting a deeper understanding of the world around us.
Through his passion for mindful and respectful foraging, Craig has become a leading advocate for the health, wellbeing, and sensory delights that come from eating wild foods. His workshops and dining experiences through 4 Wild Seasons offer a unique journey where nature meets the plate, transforming mealtimes into moments of discovery and connection.
Known for his enchanting teaching style, Craig brings a sense of magic to the world of wild food, blending culinary creativity with the wisdom of ancient food traditions. Whether you’re a beginner or a seasoned forager, Craig’s courses offer inspiration, enjoyment, and a profound connection to the natural world.

        `,
        description: '',
        images: [
            chris1, 
        ],
        links: [
            {
                image: chris10,
                title: 'Edible Leeds',
                source: 'https://edible-leeds.blogspot.com/',
                sourceName: 'Blogspot',
            },
            {
                image: instagramImage,
                title: 'Chris on Insta',
                source: 'https://www.instagram.com/edible.leeds/',
                sourceName: '@edible.leeds',
            },
            {
                image: chris2,
                title: 'Edible Leeds aka Craig Worrall',
                source: 'https://feedingbodyandsoulblog.wordpress.com/2016/06/07/edible-leeds-aka-craig-worrall/',
                sourceName: 'feedingbodyandsoul',
            },
            {
                image: facebookImage,
                title: 'Edible Leeds - Wild & Wonderful',
                source: 'https://www.facebook.com/EdibleLeeds/',
                sourceName: '@EdibleLeeds',
            },
            {
                image: chris3,
                title: 'Craig on The Association of Foragers',
                source: 'https://foragers-association.org/Sys/PublicProfile/52915577/5159216',
                sourceName: 'foragers-association.org',
            },
            {
                image: chris6,
                title: 'The Edible Outdoors, feat Chris Worrall',
                source: 'https://leeds.independentlife.co.uk/food-and-drink/the-edible-outdoors/',
                sourceName: 'leeds.independentlife.co.uk',
            },
        ]
    },
    {
        name: 'Prof. Paul Thomas',
        role: ['Speakers', 'Panelists'],
        bio: `Prof. Paul Thomas, a global authority on truffles, holds dual roles as the Managing Director of Mycorrhizal Systems Ltd (MSL) and Chief Scientist of the American Truffle Company® (ATC). 
        With an academic foundation in Natural Environmental Science from the University of Sheffield, Prof. Thomas has spent his career dedicated to the exploration and cultivation of truffles.
        He co-founded ATC with Robert Chang in 2007, revolutionising truffle cultivation with innovative scientific methodologies. 
        At MSL, Prof. Thomas has honed advanced inoculation techniques and strategic orchard management strategies, thereby reducing the typical risks in truffle farming.
        His seminal research, initiated in 2001, has fostered a broad global truffle research network under MSL, substantially expanding our comprehension of truffle biology. His contributions were recognised by BBC's Dragon's Den program.
        Prof. Thomas's tireless dedication culminated in the first scientifically cultivated Black Perigord truffle harvest in the UK in March 2015, a significant accomplishment for MSL and ATC. 
        He extended his vast knowledge to the general public as the keynote speaker at last year's Great British Truffle Festival. 
        His engaging presentations on truffle cultivation and the intriguing topic of "Truffle Sex, Insects, and DNA" were warmly received by the audience, solidifying his reputation as a compelling communicator. 
        Prof. Thomas also frequently shares his expertise through TV, radio, and various publications.`,
        description: 'Sustainable truffle cultivation in the UK',
        images: [
            paulThomas,
        ],
        links: [
            {
                image: paulBBC,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: twitterImage,
                title: 'Prof Paul Thomas FRSA',
                source: 'https://x.com/summertruffle',
                sourceName: 'X - @SummerTruffle',
            },
            {
                image: youTubeImage,
                title: 'Digging up big truffle in Australia',
                source: 'https://www.youtube.com/watch?v=1XaKFdMMryI',
                sourceName: 'YT - @americantruffle',
            },
            {
                image: paulBBC,
                title: 'BBC Countryfile Summer Diaries 2016',
                source: 'https://www.youtube.com/watch?v=ySimphlEHiM',
                sourceName: 'YT - @NativeTruffle',
            },
        ]
    },
    {
        name: 'Andy Pincott',
        role: ['Speakers', 'Panelists'],
        bio: `Andy planted 3 acres of truffle trees back in 2009 as an investment for the future and part of a wider plan to do something different after corporate life! 
        Having nurtured the plot for many years, whilst continuing to fulfil a day job in a suit. 
        Andy has now joined the ‘gig economy’ and moved full time into jobs that nurture his passions. 
        In addition to truffle farming, he runs the Instagram account @affordablewinehunter, builds outdoor bars and kitchens and completes art commissions.
        In this talk Andy will provide an honest account of what it takes to set up a truffle plantation – the highs and the lows – and will provide numerous tips to anyone thinking about this journey, or who has already started down this path.`,
        description: '“From root to plate”: The trials and tribulations of establishing a truffle orchard',
        images: [
            AndyPincott,
        ],
        links: [
            {
                image: instagramImage,
                title: '👍 Good💰Affordable 🛒 Accessible🍷wines',
                source: 'https://www.instagram.com/affordablewinehunter',
                sourceName: '@affordablewinehunter',
            },
            {
                image: tiktokImage,
                title: 'Tips for Good, Affordable and Accessible wine',
                source: 'https://www.tiktok.com/@affordablewinehunter',
                sourceName: '@affordablewinehunter',
            },
        ]
    },
    {
        name: 'Melissa Waddingham',
        role: ['Speakers', 'Panelists'],
        bio: `A professional truffle hunter and wild mushroom picker. Melissa has been in the industry for 16 years. 
        In the spring and autumn months running mushroom forays, truffle hunts, talks, courses and throughout the year providing truffle hound training days for your truffle hounds to be at beginner, intermediate or experienced level.
        Melissa founded the Great British Truffle Festival in 2022-2023 and has re-launched the festival under a new name and in partnership with Michael Corcoran. 
        The Great British Truffle Festival.
        Melissa offers truffle hound Zoom courses which have been a great addition to her one-to-one or group classes.
        Melissa also manages existing woodlands for the sustainable production of truffles, carries out woodland surveys to establish their presence and records fungi species for landowners unaware of what they have on their land.
        Melissa is also a passionate cook and especially enjoys creating interesting and flavoursome food for clients on her forays or for private events and festivals, recipes always including wild fungi and truffles.`,
        description: 'Sustainable hunting, duty of care and the law',
        images: [
            melissaTruffle
        ],
        links: [
            {
                image: melissaTruffle,
                title: "Melissa's Website",
                source: 'https://truffleandmushroomhunter.com/',
                sourceName: 'Truffle And Mushroom Hunter',
            },
            {
                image: melissaTruffle,
                title: "Melissa on Facebook",
                source: 'https://www.facebook.com/truffleandmushroomhunter',
                sourceName: '@truffleandmushroomhunter',
            },
            {
                image: melissaTruffle,
                title: 'Sussex Truffles & the Great British Truffle Festival',
                source: 'https://sussexexclusive.com/sussex-truffles-a-truffle-festival/',
                sourceName: 'Sussex Exclusive',
            },
            {
                image: melissaTruffle,
                title: "Melissa on Insta",
                source: 'https://www.instagram.com/truffleandmushroomhunter/',
                sourceName: '@truffleandmushroomhunter',
            },
        ]
    },
    {
        name: 'Jesper Launder',
        role: ['Workshops'],
        bio: `Jesper developed a fascination for fungi as a child after a couple of memorable fungi-related events; 
        a magical mushroom foray with close family, skipping through the woods collecting mushrooms for the basket (the intention had been for the adults to identify them using a nature ID book, but they sadly ended up in the bin, unidentified).
        Then spotting, and collecting a mushroom seen on the way home from school, knowing it matched perfectly one of the species on a mushroom poster on the fridge at home. 
        The perfect Shaggy Ink Cap was then eaten with his family, the first time Jesper had enjoyed eating a mushroom! 
        What followed was a rapid descent into the mushroom-shaped wormhole of fungi obsession. 
        As an adult Jesper trained as a medical herbalist, developing a particular interest in the medicinal mushrooms. 
        Edible mushrooms had always been a strong interest and Jesper has been sharing knowledge and experience on fungi ID courses for the last 25 years. 
        In more recent years, following a handful of random truffle finds on forays, and independently, he has been honing the skills of finding truffles without a dog, primarily by looking for evidence of truffle excavation by foraging animals. 
        Unearthing one of the many possible native species is both thrilling and fascinating. It's very much like a lottery; you simply don't know what your find is until it's uncovered, smelt, or in some cases, until it is under the microscope.`,
        description: 'Microscopes, Mycology and Truffles',
        images: [
            JesperLaunder
        ],
        links: [
            {
                image: JesperLaunder,
                title: "Jesper's Website",
                source: 'https://www.jesperlaunder.com/',
                sourceName: 'jesperlaunder.com',
            },
            {
                image: facebookImage,
                title: "Jesper, Medical Herbalist, on Facebook",
                source: 'https://www.facebook.com/consultingmedicalherbalist',
                sourceName: '@jesperlaunder',
            },
            {
                image: twitterImage,
                title: "Jesper on Twitter",
                source: 'https://x.com/jesperlaunder',
                sourceName: '@jesperlaunder',
            },
            {
                image: facebookImage,
                title: "Jesper on Facebook",
                source: 'https://www.facebook.com/jesper.launder/',
                sourceName: '@jesper.launder',
            },
            {
                image: instagramImage,
                title: "Jesper on Insta",
                source: 'https://www.instagram.com/launderjesper/',
                sourceName: '@launderjesper',
            },
        ]
    },
    {
        name: 'Sarah J Ebdon',
        role: ['Workshops'],
        bio: `S-J will be working alongside Jesper with the microscope workshop. ⁠S-J is an artist, educator and lifelong mycophile whose fascination just gets bigger as she learns more about what she doesn’t know. She first encountered fungi as a child when introduced to the joy of foraging mushrooms from the garden by her Mum and has explored a deepening connection to the local landscape through the fungi that live there ever since.⁠
⁠In recent years diving into the realm of microscopy has opened up new layers of wonder and excitement. She sees the awe-inspiring architecture of nature as a place where art and science collide.⁠⁠
S-J is a regular contributor to Bucks Fungus Group (http://www.bucksfungusgroup.org.uk/) and their outreach work. She loves to explore ways of learning and noticing and seeing awe sparked in others. Believing that when we connect in a sensorial way to the world around us that’s when we become truly alive. ⁠
`,
        description: 'Microscopes, Mycology and Truffles',
        images: [
            SJ
        ],
        links: [
            {
                image: SJ,
                title: 'TruffleTV: Dr. Paul Thomas on BBC',
                source: 'https://www.youtube.com/watch?v=CQ7H49lT4Mk',
                sourceName: 'YT - @americantruffle',
            },
        ]
    },
    {
        name: 'Neville Kilkenny',
        role: ['Panelists'],
        bio: `
Having studied mycology at The Royal Botanic Garden Edinburgh under the guidance of Prof. Roy Watling 17 years ago, I retain links as a Research Associate and continue to run workshops and forays for the Garden. 
Clients include The James Hutton Institute, NatureScot, Natural England, and RSPB. I am a member of the British Mycological Society, The Fungus Conservation Trust, and an honorary life member of The Danish Mycological Society. Working now as a freelance mycologist, focussed mainly on conservation and understanding the diversity and ecological needs of fungi, I am also a keen forager and enjoy sharing my passion for these incredible organisms and educating people regarding their role in our ecosystems and in our everyday lives. 
        `,
        description: 'Consultant mycologist and fungal ecologist. ',
        images: [
            Neville
        ],
        links: [
            {
                image: Neville,
                title: "Neville's Website",
                source: 'https://www.fungi.co.uk/',
                sourceName: 'fungi.co.uk',
            },
            {
                image: twitterImage,
                title: "Neville on Twitter",
                source: 'https://x.com/NevKilkenny',
                sourceName: '@NevKilkenny',
            },
            {
                image: Neville,
                title: "Neville at the Royal Botanical Gardens",
                source: 'https://www.rbge.org.uk/whats-on/fungi-identification-for-beginners/57463',
                sourceName: 'Royal Botanical Garden Edinburgh Course',
            },
            {
                image: instagramImage,
                title: "Neville on Insta",
                source: 'https://www.instagram.com/funginev/',
                sourceName: '@funginev',
            },
            {
                image: Neville,
                title: "Neville at The Association of Foragers",
                source: 'https://foragers-association.org/Sys/PublicProfile/67882624/5159216',
                sourceName: 'The Association of Foragers',
            },
            {
                image: facebookImage,
                title: "Neville on Facebook",
                source: 'https://www.facebook.com/neville.kilkenny',
                sourceName: '@neville.kilkenny',
            },
            {
                image: Neville,
                title: "A brief introduction to fungi and how to forage without fear",
                source: 'https://nightowlbooks.co.uk/products/a-brief-introduction-to-fungi-and-how-to-forage-without-fear',
                sourceName: 'nightowlbooks.co.uk',
            },
        ]
    },
    {
        name: 'Alexandra Lea',
        role: ['Panelists'],
        bio: `
Alexandra Lea is a renowned biologist and mycologist whose passion for mushrooms has made her a leading figure in the field of fungal
studies. With a deep-rooted connection to nature, Alexandra has spent years exploring the diverse world of mushrooms, blending scientific rigor with a profound intuitive understanding of the forest. Her expertise and enthusiasm shine through in the transformative mushroom identification workshops she leads across the UK. These workshops not only educate participants on the intricacies of muushroom identification but also foster a deeper appreciation for the edible and medicinal treasures found in nature. Alexandra’s dedication to mushroom foraging is matched by her ability to inspire others to forge their own connections with the natural world, sharing her boundless enthusiasm for the fungi kingdom with everyone she encounters.
        `,
        description: '',
        images: [
            Alexandra
        ],
        links: [
            {
                image: Alexandra,
                title: 'Medicinal and Edible Mushroom Foraging in a Sussex Forest',
                source: 'https://dandelion.events/e/g0b9pr',
                sourceName: 'dandelion.events',
            },
            {
                image: instagramImage,
                title: 'Alexandra on Insta',
                source: 'https://www.instagram.com/the_female_forager/',
                sourceName: '@the_female_forager',
            },
            {
                image: twitterImage,
                title: 'Alexandra on Twitter',
                source: 'https://x.com/female_forager',
                sourceName: '@female_forager',
            },
        ]
    },
];
