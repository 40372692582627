import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="bg-light text-center text-lg-start">
            <div className="text-center p-3" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
                © 2024 TrueTruffle Festival
                <br />
                <a className="text-dark" href="https://blog.thegreatbritishtrufflefestival.co.uk/">Blog</a> | 
                <Link className="text-dark" to="/privacy">Privacy Policy</Link> | 
                <Link className="text-dark" to="/terms">Terms of Service</Link> | 
                <Link className="text-dark" to="/credits">Credits For Images</Link>
            </div>
        </footer>
    );
}

export default Footer;
